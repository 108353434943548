// more info boxes used on Programme Detail page
.page-template-page-programme-detail{


	.moreInfo{
			background-color: $lightGrey;
			@include clearfix();
			img{
				float: left;
				margin: 0 1rem 1rem 0;
				width: 40%;
				max-width:150px;
			}
			div{
				padding: 1.5rem;
			}
	    @include breakpoint($medium) {
	    	.columns2{ // this class only gets assigned if both testimonial and funded by fields are present
		    	float: left;
		    	width: 50%;
	    	}
	    }	
	}



}