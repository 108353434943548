.showCommentsButton{
    padding: .75rem 1rem;
    float: right;
    margin: 0 1.2rem 0 0;
    min-width: 0;
    background: $green !important;
    text-decoration: none !important;
    color: #fff !important;

    &:after{
        content: " ";
        background: url(img/icon-comment.svg) no-repeat;
        background-size: cover;
        width: 1.1rem;
        height: 1rem;
        display: block;
        float: right;
        margin: .25rem 0 0 .25rem;        
    }
    &:hover{
        background: darken($green, 7%) !important;    
    }
}

.comments-cta {
   clear: both;
   background-color: $green;
   padding: 20px;
   margin-bottom: 3.5rem;

   &__title {
        color: white;
        margin-bottom: 0px;

        &:before{
            content: " ";

            background: url(img/icon-comment.svg) no-repeat;
            background-size: cover;
            width: 2.2rem;
            height: 2rem;
            display: block;
            float: left;
            margin: .25rem 1.25rem 0 0;        
        }
   }
}