.modalElement {
	position: fixed;
	top: 50%;
	left: 50%;
	width: 90%;
	max-width: 740px;
	min-width: 300px;
	height: auto;
	z-index: 2000;
	visibility: hidden;
	backface-visibility: hidden;
	transform: translateZ(0) translateX(-50%) translateY(-50%) scale(0.7);
    background-color: $midGrey;
    padding: 1.875rem;
    transition: all 0.3s ease-in-out;
    opacity: 0;

    @include breakpoint($nav) {
        padding: 2.5rem;
    }
        
    &-show {
	    visibility: visible;
        transform: translateZ(0) translateX(-50%) translateY(-50%) scale(1);
        opacity: 1;
        transition: all 0.3s ease-in-out;
    }

    &-close {
        background: url('img/modal-close.png') no-repeat;
        background: url('img/modal-close.svg') no-repeat;
        background-size: contain;
        color: $brownGrey;
        width: 1.5rem;
        height: 1.5rem;
        position: absolute;
        top: 0.75rem;
        right: 0.75rem;

        @include breakpoint($nav) {
            width: 2rem;
            height: 2rem;
            top: 1rem;
            right: 1rem;
        }

    }

}

.modalElement-overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	visibility: hidden;
	top: 0;
    bottom:0;
	left: 0;
    right: 0;
	z-index: 1000;
	opacity: 0;
	background: $brownGrey;
	transition: all 0.3s;
    overflow: hidden;
}

.modalElement-show ~ .modalElement-overlay {
	opacity: 0.9;
	visibility: visible;
}

.lt-ie9 .modalElement {
    margin: auto;
    top: 30%; 
    left: 0; 
    right: 0;
}