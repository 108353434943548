$x4Headers:   640px;


.X4headerLinkBanners{
			display: block;
			@include clearfix();
			margin: -0.5rem 0 0 0;

    	a{
    		position: relative;
			display: inline-block;
			margin: 0 0 1rem 0; 
			background-color: $brownGrey;
		}
		a:hover:before {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background: $imageHover;
			transition: background .3s linear;
		}	
	@include breakpoint($nav) {		
		margin: -1rem 0 0 0;
	}
	@include breakpoint($x4Headers) {		
	    a{
	    	float: left;
	    	width: 50%;
	    	background-position: center;
	    	background-size: cover;
	    }				
	    a:nth-child(odd){
			border-right: .5rem solid #fff;
	    }
	    a:nth-child(even){
			border-left: .5rem solid #fff;
	    }
	}
	    	
    		
	img{
			display: block;
			width: 100%;
		@include breakpoint($x4Headers) {
			visibility: hidden;	    		
		}    		
	}

	span{
		background: $brownGrey;
		color: #fff;
		padding: .5rem 1.5rem;
		line-height: 1.3;
		position: absolute;
		bottom: 1.5rem;
		font-weight: bold;
	}


	a:hover img{
		opacity: .90;
	}
}