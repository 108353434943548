// Loader animation adapted from here:
// https://github.com/MatejKustec/SpinThatShit

$loader-size:					4rem;
$loader-height:					3rem;
$loader-border-size:			0.5rem;
$loader-gap:					5px;
$loader-animation-duration: 	0.75s;

@mixin mapLoader(
	$size: $loader-size,
	$border-size: $loader-border-size,
	$duration: $loader-animation-duration,
	$align: null
) {
	width: $size;
	height: $size;
	border: $border-size solid rgba($green, .25);
	border-top-color: $green;
	border-radius: 50%;
	position: relative;
	animation: loader-rotate $duration linear infinite;
	@if ($align == center) {
		margin: 0 auto;
	}
	@if ($align == middle) {
		top: 50%;
		margin: -$size/2 auto 0 auto;
	}
	@include loader-rotate;
}


@mixin loader-rotate {
	@keyframes loader-rotate {
		0% {
			transform: rotate(0);
		}
		100% {
			transform: rotate(360deg);
		}
	}
}