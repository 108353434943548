h1, h2, h3, h4, h5, p, ul, blockquote{
	margin:0 0 1rem 0;	
}

p, ul,
nav.subNav ul.children a // sub nav child links
{
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 1.6;
}

.small, small,
.entry .subTitle,
.textEntry .subTitle{ /* secondary paragraph size  <small> tag used for smallprint */
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.4;
}

.smallPrint{
    font-size: 14px;
}

.bannerTitle{
    font-size: 30px;
    font-size: 1.9rem; //30px
    line-height: 1.2;
    margin-bottom: .5rem;
}

.headerSlider h2{ // titles in banner
        font-size: 1.2rem;   
        margin-bottom: 1rem;  
    @include breakpoint($medium) {
        font-size: 30px;
        font-size: 1.9rem; //30px   
        line-height: 1.3;  
    }   
    @include breakpoint($large) {
        font-size: 41px;   
        font-size: 2.563rem;   
        line-height: 1.3;  
    }       
}
.slider-nav .menuTitle, // nav for slider used on homepage
.factBox span, // from bshfInNumbers 
aside.rightSideBar h4 // awards archive page facet titles
{ 
    font-size: 17px;
    font-size: 1.063rem;
}

h1{
    font-size: 44px;
    font-size: 2.750rem;
    letter-spacing: -0.08rem;
    line-height: 1;       
    margin-bottom: 3rem;        
}
h2,
.sidebarBanner span.title
{
    letter-spacing: -0.03rem;
    font-size: 23px;
    font-size: 1.438rem; 
    line-height: 1.3;    
    margin-bottom: 2rem;   // is this too much? As used on subtitle on awards detail 
}

// there's a discrepancy in text in the designs the site, sometimes big text is 19px someimes it's 20px
// This should be standadised!
h3{
    //font-size: 1.06rem; // 19px
    //font-size: 1.12rem; // 20px
    font-size: 26px;
    font-size: 1.625rem;
    line-height: 1.4;
    letter-spacing: -0.01rem;          
}

.awardsHomeBanners .bannerTitle // awards home page main banner title
{
        //letter-spacing: -0.13rem;
        font-size: 29px;
        font-size: 1.813rem;
    @include breakpoint(620px) {
        letter-spacing: -0.13rem;
        font-size: 58px;
        font-size: 3.625rem;
    }
    @include breakpoint(850px) {
        font-size: 44px;
        font-size: 2.750rem;
    }            
    @include breakpoint($xlarge) {
        letter-spacing: -0.13rem;
        font-size: 58px;
        font-size: 3.625rem;
    }    
}

h4,
.facet .searchTitle, // facet search title
.carouselItem a,
.entry .title, // cEntry
.textEntry h3, // textEntry
nav.subNav ul > li > a // subnav top level links
{ 
    letter-spacing: -0.025rem;
    font-size: 19px;
    font-size: 1.188rem;
}

.headerSlider .button, // homepage cta button in slider
.mainBanner .button, // wha main homepage banner button
.sideBanner .title, // wha side homepage banner button
.peerExchanges .button // wha homepage peer exchanges button
{
        font-size: 19px;
        font-size: 1.188rem;
    @include breakpoint($large) {
        font-size: 21px;
        font-size: 1.313rem;
    }
}
.X4headerLinkBanners span{
    font-size: 29px;
    font-size: 1.813rem;
    line-height: 1.4;          
}
blockquote, blockquote p{
    font-size: 1.7rem;
    line-height: 2.5;
    clear: right;
    color: $darkGrey;
    margin: 1rem 0 2rem 0;
}


// default link hover state
a:not(.button) {
    &:hover, &.hovered{
        color: $hotPink;
    }
}



.pageHeader, article, section, .translateWidget,{
    a:hover, a.hovered{
        text-decoration: underline;    
    }
}

// Page header preceeds most articles and contains H1 and meta data
.pageHeader{
    //margin:  0 auto 2rem auto;
    & > h1{
        margin-top: 2.5rem;
    }
    .subTitle{
        margin-top: -1.5rem;

        @include breakpoint($nav) {
            margin-top: -1.75rem;
        }

        @include breakpoint($medium) {
            margin-top: -1.5rem;
        }
            
    }
}
.pageHeader.center{
    max-width: 860px;
    margin: auto;
}


// all CMS content fields appear in article, excluding articles with the class columns
article:not(.columns){
    img + p, & > p:first-of-type{
        transform: translateY(-.5rem); // fixes line hight issue misaligning top rag of columns
    }
    h3 {
        margin-bottom: 10px;
    }  
    .postThumbnail{
        clear: left;
        display: block;
        margin: 0 1.5rem 1rem 0;
        float: left;
    }
}
article p a, article ul a, article ol a {
    font-weight: bold;
}

// WHA section overides
.whaPage{
    article p a, article ul a, article ol a {
        color: $orange;
        &:hover{
            color: $orange; 
        }
    }
}

// Main navigation font sizes 
// This is a bit messy but we need to try and target different amounts of text in the menu
// that will change between languages.
// VW units do a good job of allowing us to scale the size with the browser width

nav.mainNav ul{
        font-size: 1.286rem;
    @include breakpoint($nav) {  
        font-size: 12px;
        font-size: 1.5vw;
        //background-color: yellow;
    }
    @include breakpoint(1220px) {  
        font-size: 12px;
        font-size: 1.3vw;
    }
    @include breakpoint(1370px) {  
        font-size: 18px;
        font-size: 1.125rem; //18px
        //background-color: beige;
    }
}


ul.list, article ul, article ol, .content ul, .content ol{ // make lists look like lists again
	padding:0 0 0 3rem;
    margin:0 0 1rem 0;
    overflow: hidden; // prevents list wrapping weridly around floated images
	li{
        margin:0 0 .3rem 0;
        display:list-item;
    }
}
.subTitle{
    color: $darkGrey;
    font-weight: normal;   
}
.last-updated {
    font-size: 0.9rem;
    font-style: italic;
    margin-top: -1rem;

    &__top-margin {
        border-top: 1px solid $midGrey;
        padding-top: 15px;
        display: inline-block;
    }
}
time{
    font-weight: bold;
}
// styles for std buttons and form buttons (incl Gravity Forms)
.button, input[type="submit"], input[type="button"] {
    padding: .75rem 1.5rem .75rem 1.5rem;
    background: $hotPink;
    color: #fff;
    font-weight: bold;
    text-align: center;
    display: inline-block;
    line-height: 1.4;
    min-width: 13rem;
    cursor: pointer;  
    width: auto;  
    img{
            vertical-align: text-bottom;
            margin-left: 5px;
        @include breakpoint($max) {            
            transform: translateY(-2px)
        }    
    }
    &:hover{
        text-decoration: none;
        background-color: darken($hotPink, 10%);
    }
}
.button.green, input[type="submit"]{
    background: $green;
    &:hover{
        background-color: darken($green, 10%);
    }

}
.button.arrow{
    min-width: 11.5rem;
    text-align: left;
    position: relative;
    padding: .75rem 2.5rem .75rem 1.5rem;    
}
.button.arrow:after{
    content: " ";
    background: url('img/icon-arrow-white.svg') no-repeat center center;
    background-size: contain;
    width:  .75rem;
    height: .75rem;
    top: 50%;
    transform: translateY(-.375rem);
    display: block;
    position: absolute;
    right: 1rem;  
}   

// WHA section button overides
.whaPage{
    // overide button colour
    .button, input[type="submit"], input[type="button"]{
        background-color: $orange;
        color: $bodyTextColor;
        &:hover{
            background-color: darken($orange, 6%);
        }
    }
}

// Cookie consent styling overrides
.cc-color-override--794000627 .cc-btn:hover, .cc-color-override--794000627 .cc-btn:focus {
    color: #fff;
    background-color: #008c83;
}


span.credit{ // image credit
        position: absolute;
        display: block;
        color: #fff;
        background: rgba(0, 0, 0, 0.50);
        font-size: 11px; 
        line-height: 1.2;
        padding: 2px 5px;
        text-align: right;
        right: 0;
        top: 0;
    @include breakpoint($medium) {    
        top: auto;
        bottom: 0;    
    }
}
.center{
    text-align: center;
}
hr { // A better looking default horizontal rule
    display: block;
    clear: both;
    height: 1px;
    border: 0;
    border-top: 1px solid $midGrey;
    margin: 0 0 3.5rem 0;
    transform: translateY(1rem);
    padding: 0;
}



html{ /* fonts are sized in REM to allow global shift in size at different breakpoints */
        font-size: 16px;
        //background:yellow;
    @include breakpoint($small) {
        font-size: 16px;
        //background:pink;
    }    
    @include breakpoint($medium) {
        font-size: 16px;
        //background:brown;
    }    
    @include breakpoint($large) {
        font-size: 16px;
        //background:orange;
    }   
    @include breakpoint($max) {
        font-size: 16px;
        //background:grey;
    }                
}
