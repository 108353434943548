.page-template-home{

	// Overrides -1.5rem margin from the top of the X4headerLinkBanners.scss partial line 7.
	.X4headerLinkBanners{
		margin: 0 0 1.5rem 0;
	}
	
	.headerSlider{
		@include clearfix();
		position: relative;

		.banner{
				width: 100%;
			    max-height: 530px;
				position: relative;
	    		background-position: center;
	    		background-size: cover;	
			img{
					display: block;
					width: 100%;
				@include breakpoint($medium) {
					visibility: hidden;	
					min-height: 400px;
					max-height: 530px;    		
				}    		
			}  	
			h2{
				min-height: 48px;
			}
			h2:last-of-type{
				margin: 0;
			}

			@include breakpoint($medium) {
				.text__wrapper {
					max-width: $pageWidth;
					margin: 0 auto;
				    position: absolute;
				    width: 100%;
				    top: 0;
				    left: 0;
				    bottom: 0;
				    right: 0;
				}
			}
			.text{	
					background: $brownGrey;
					color: #fff;
					text-align: center;
					padding: $gutter;
				@include breakpoint($medium) {
					background-color: transparent;
					text-align: left;
					margin-right: 3rem;
					max-width:60%;
					max-width: 750px;
					position: relative;
					left: 4.5%;
				  	top: 50%;
				  	transform: translateY(-50%);
				}  
				@include breakpoint($xlarge) {
					
					
				}


			}
			p{
				margin: 0;
			}
		}// end .banner
		@at-root .no-js{
			.banner, .slider-nav{
				display: none;
			}
			.slider-main div:first-of-type{ 
				display: block;
			}
		}
		.slider-nav{
					text-align: center;
					font-weight: bold;
					color: #fff;
					width: 100%;
					
				@include breakpoint($medium) {	
					position: absolute;
					bottom: 0;
					//min-height: 3rem;
					background-color: rgba($brownGrey, 0.9);
				}				
			.menuTitle{
					display: none;
				@include breakpoint($medium) {	
					cursor: pointer;
					display: block;			
					margin: 0 2rem;
					padding: 1.26rem 0;
					position: relative;				
				}
			}
			.is-selected {
				@include breakpoint($medium) {	
					&:after{
						content: " ";
						height: 5px;
						position: absolute;
						left: 0;
						right: 0;
						bottom: 0;	
						background-color: $orange;
					}
				}
			}
			.flickity-page-dots{
					display: block;
				@include breakpoint($medium) {				
					display: none;	
				}
			}
		}



	}

	.pageHeader{
		@include breakpoint($medium) {				
		    margin-bottom: 2.5rem;
		}
	}
}