body {

    &:before {
        display: none;
        content: 'none'; 
        @include breakpoint($nav) {
            content: 'nav'; 
        }  
    }

    &:after {
        display: none;
        content: 'none'; 

        @include breakpoint($small) {
          content: 'small'; 
        }    
        @include breakpoint($medium) {
         content: 'medium'; 
        }
        @include breakpoint($large) {
         content: 'large'; 
        }   
        @include breakpoint($max) {
         content: 'max'; 
        } 
    } 
}

