.share{
	// /background-color: yellow;
	@include clearfix();
	float: right;
	position: relative;

	ul{
		float: right;
		list-style: none;
	    padding: 1rem 0.5rem 0.5rem 0.5rem;
		//position: absolute;
	    top: 100%;
	    text-align: center;
	    background-color: $lightGrey;
		right: 0;
		left: 0;
		margin: 0 auto;
	    z-index: 2;

		li{
	   		display: inline-block;
	   		margin: 0rem 0rem 0.25rem 0rem;
	   		position: relative;
	   		z-index: 2;
   		    width: 47%;
   		    height: 2.25rem;

	   		a {
	   			display: inline-block;
	   			height: 2.25rem;
	   		}

	   		&:last-child {
				margin: 0rem;
			}

			&:before {
				position: absolute;
				display: block;
				content: "";
				background-color: white;
				width: 23px;
				top: 2px;
		        height: 1.5em;
				left: 0px;
				right: 0px;
				margin: 0 auto;
				z-index: -1;
			}

		}

		img{
			width: 1.8rem;
		}
	}
	.shareTitle{
		text-align: center;
	    background-color: $lightGrey;
	    padding: 1rem;
	    margin-bottom: -1rem;
	    font-weight: bold;
	}

	#shareButton{
		float: right;
		margin: 0 0 0 0rem;
		padding:.75rem 1rem;
		font-weight: bold;
		display: block;
		background: $green;
		color: #fff;
		
		&:after {
			content: " ";
			background: url('img/icon-share.svg') no-repeat;
			background-size: cover;
			width: 1rem;
			height: 1rem;
	    	display: block;
	    	float: right;
	    	margin: .25rem 0 0 .25rem;
		}
	    &:hover{
	        background: darken($green, 7%) !important;    
	    }		
	}
}