// Rebase - custom CSS reset/defaults by Duncan Kendrick (www.dkendrick.co.uk)
// Based on https://github.com/necolas/normalize.css/blob/master/normalize.css and http://jaydenseric.com/blog/forget-normalize-or-resets-lay-your-own-css-foundation


// Variable defaults - usually you'll want to assign these in your main project SCSS file
  $bodyFont : sans-serif !default;
  $bodyTextColor : #333333 !default;
  $bodyLinkColor : #496fba !default;
  $selectionBkg : darken( $bodyLinkColor, 20% ) !default;
  $selectionTxt : lighten( $bodyLinkColor, 20% )!default;
//


*, *:before, *:after {
  box-sizing: inherit; // fixes anoying box sizing
}
html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    box-sizing: border-box; // fixes anoying box sizing
}
body {
    margin: 0;
    font: 16px/1.375rem $bodyFont; // good line height for body copy
    color:$bodyTextColor;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

input, textarea, select{font-family:inherit;} //makes form elements use the body font rule

h1,
h2,
h3,
h4,
p,
small,
blockquote,
figure,
ol,
ul {
    margin: 0;
    padding: 0;
}
main,
li {
    display: block;
}
h1,
h2,
h3,
h4 {
    font-size: inherit;
}
p,li{
	widows: 3; orphans: 3; // prevents widows and orphans
}
strong {
    font-weight: bold; //set to `bolder` in Firefox 4+, Safari, and Chrome.
}
a,
button {
    color: inherit;
    transition: .3s;
}
a {
	background-color: transparent; // Remove the gray background color from active links in IE 10.
    text-decoration: none;
    color:$bodyLinkColor;
}
a:active, 
a:hover {
  outline: 0; // Improve readability of focused elements when they are also in an active/hover state.
}

// Prevent `sub` and `sup` affecting `line-height` in all browsers.
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}
button {
    overflow: visible;
    border: 0;
    font: inherit;
    -webkit-font-smoothing: inherit;
    letter-spacing: inherit;
    background: none;
    cursor: pointer;
}
input[type="button"], input[type="submit"], input[type="reset"], input[type="file"], button {
	border-radius: 0; // chrome osx adds rounded corners to buttons to make them more like native buttons, remove it. 
}
::-moz-focus-inner {
    padding: 0;
    border: 0;
}
:focus {
    outline: 0;
}
/*
 * Remove text-shadow in selection highlight:
 * https://twitter.com/miketaylr/status/12228805301
 *
 * These selection rule sets have to be separate.
 * Customize the background color to match your design.
 */
::selection {
    background: $selectionBkg;
    color: $selectionTxt;
    text-shadow: none;
}

img {
    max-width: 100%;
    height: auto;
    border: 0; //Remove border when inside `a` element in IE 8/9/10.
}
svg:not(:root) {
  overflow: hidden; //Correct overflow not hidden in IE 9/10/11.
}

// Helper mixins. These are kept to bare minimum
@mixin clearfix() {

  &:after {
    content: "";
    display: table;
    clear: both;
  }
}
.clearfix{
  @include clearfix();
}


