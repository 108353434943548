body{ border-top: 7px solid transparent; }
body.whaPage{ border-color: $orange; } // wha pages have orange stripe at top

header.page{
		max-width: $pageWidth;
		padding: 0 1rem 0 1rem;
		margin: 0 auto 0 auto;
		@include clearfix;
		position: relative;

	.logo, .whaLogo{
			width: 22.5%;
			min-width: 200px;
			max-width: 307px;
			display: block;
	    	float: left;
	    	margin: $gutter 0 .7rem 0;
	    @include breakpoint($nav) {	
	    	margin: $gutter 0 0 0;
	    }
	}
	// special rule for UK only version to accomodate nav alongside logo
	@at-root body.lang-en .logo, .whaLogo{
	    @include breakpoint(1200px) {	
	    	margin: 2.25rem 0 1.5rem 0;
	    }
	}


	.headerToolsWrap{
	    @include breakpoint($nav) {	
		    margin:1.5rem 0 0 0;
			padding: 0 0 1rem 0;	
		    position: absolute;
		    right: 1rem;
		    bottom: 1.2rem;
		    top: 0;			
		    min-width: 310px;
		}
	}
}
.translateWidget{
		display: none;
		font-size: 1rem;
		text-align: right;
		position: absolute;
		top: 1.2rem;
		right: 5rem;
		color: $midGrey;		
		
	@include breakpoint($small) {			
		display: block;
	}

    @include breakpoint($nav) {	
    	background: none;	
	    bottom: auto;
	    top: 0; 
        left: -3rem; 
        right: 3rem;  	
    	margin: 0;
		text-align: left;
    }	

	@include breakpoint($small) {
		padding: 0 0 0rem 0;
	}

	@include breakpoint($large) {
		padding: 0 0 1rem 0;	
	}

	a{
	 	color: $darkGrey;
	}
	#google_translate_element{
			display: none; // widget doesnt work responsively
	    @include breakpoint(550px) {		
		    transform: translateY(-2px);
		    display: block;
			float: right;
			margin: 0 0 0 .5rem;
	    }		    
	}
}
// at very small screen sizes, make the language selector show in burger nav
@at-root .js-main-nav-active .translateWidget{
	@include breakpoint(0 $small) {
		display: block;
		z-index: 100;
		a{ color: #fff;}
	}
}

.searchIcon{
		display: none;
	@include breakpoint($nav) {	
		display: block;
		position: absolute;
		right: 0;
		top: 3px;
		span{ display: none;}
		width: 18px;
		height: 18px;
		background: url(img/svgLoader.php?img=icon-search-white.svg&fill=%23e2407b) no-repeat; // fill with $hotPink hex
		background-size: cover;
		
		&:hover{
			background-image: url(img/svgLoader.php?img=icon-search-white.svg&fill=%23d20a36); // fill with $red hex
		}
	}
}
.skip-link{
	background-color: $lightGrey;
    color: $green;
    display: block;
    font-weight: bold;
    left: -9999em;
    outline: none;
    padding: 1rem;
    text-decoration: none;
    text-transform: none;
    top: -9999em;

	clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute !important;
    width: 1px;    
}
.skip-link:focus {
    clip: auto;
    height: auto;
    left: 0;
    top: 0;
    width: auto;
    z-index: 100000;
}


// some pages need a keyline below the nav (most dont)
.page-template-archive-staffblog-cpt nav.mainNav,
.page-template-archive-press_release-cpt nav.mainNav,
.page-template-archive nav.mainNav,
.page-template-archive-awards-cpt nav.mainNav,
.page-template-page-contact nav.mainNav,
.page-template-page-full-width nav.mainNav,
.post-type-archive-staffblog-cpt nav.mainNav,
.post-type-archive-press_release-cpt nav.mainNav,
.single-news-cpt nav.mainNav,
.single-staffblog-cpt nav.mainNav,
.single-press_release-cpt nav.mainNav,
.single-awards-cpt nav.mainNav{
	border-bottom: 1px solid $lightGrey;
	margin-bottom: 1.5rem;
}

.wrapper{
	max-width: $pageWidth;
	padding: 0 $gutter;
	//margin: 1.5rem auto 0 auto;
	margin: 1rem auto 0 auto; // top margin changed so the sub nav menu displays at the correct position
	position: relative;
	@include clearfix();
}

main{
	
	article{
		margin: 1.5rem 0 0 0;
		//max-width: 60rem; // can't do this - breaks publication articles
		@include clearfix();
	}

	article.columns{

		margin-top: 0.75rem;
		margin-bottom: 0.75rem;

		@include breakpoint($medium) {		
			columns: 2;
			column-fill: balance;
    		column-gap: 4rem;
			max-width: none;
			//padding: 30px 0px;

			.break{
				break-before: always;
			    -moz-column-break-before: always;
			    -webkit-column-break-before: always;
			}
    	}
	}
}

.lineBelow{
	padding-bottom: 3rem !important;
	margin-bottom: 3rem !important;
	border-bottom: 1px solid $lightGrey;
}
.lineAbove{
	padding-top: 3rem !important;
	margin-top: 3rem !important;	
	border-top: 1px solid $lightGrey;	
}

// 2 column layout (left sidebar)
// LeftHandSideBar sits outside of 'main', 'article' is 100% wide
main.hasLeftHandSideBar, main.hasLeftHandSideBar .underArticle{ 
	@include breakpoint($medium) {		
		width: 76%;
		padding: 0 0 0 ($gutter*2);
		float: right;
	}
}
aside.leftSideBar, nav.subNav{     
		@include clearfix();  
		clear: left;
	@include breakpoint($medium) {	
		float: left;
		width: 24%;
	}
}





// 3 column layout
// RightHandSideBar sits inside of 'main' floating to right of 'article' 
main.hasLeftHandSideBar.hasRightHandSideBar{ 
	@include breakpoint($medium) {		
		article{
		    width: 65%;
		    padding: 0 2% 0 0;
		    margin: 0 2% 0 0;
		    float: left;
			border-right: 1px solid $lightGrey;
		}
		aside.rightSideBar{
			width: 33%;
    		float: right;			
		}
	}
}

@at-root .no-js main.hasLeftHandSideBar.hasRightHandSideBar article{
	width: 98%;
	border-right: none;
}	

// 2 column layout (right sidebar)
// a combination of the above 2 layouts!
main.hasRightHandSideBar{
	@include breakpoint($medium) {		
		article, .underArticle{ 
		    width: 75%;
		    padding: 0 2% 0 0;
		    margin: 0 2% 0 0;
		    float: left;
			border-right: 1px solid $midGrey;
		}
		aside.rightSideBar{
			width: 23%;
    		float: right;			
		}
	}
}

.breadcrumbs{
		padding: 0 0 .5rem 0;
		margin: 0.5rem 0 0 0;
		text-align: left;
		border-bottom: 1px solid $midGrey;
}

@at-root .whaPage .breadcrumbs a{ // WHA section breadcrumb overides
	color: $orange;	
	&:hover{
		color: $orange;	
	}
}

.facetwp-pager,
.pagination{
	text-align: center;
	line-height: 1 !important;
	border-top: 1px solid $lightGrey;
	padding: 1rem 0;
	
	@include clearfix;

	.pageXofY{
		display: block;
		margin: 0 0 1.5rem 0;
	}
	.last, .first{
		display: none;
	}
	a, .current{
		display: inline-block;
		background: $green;
		color: #fff;
	    font-size: 1rem;
		padding: .75rem;
		font-weight: bold;
	}
	.facetwp-page.current{
		font-size: 1rem;		
		background: $green;
		color: white;
	}
	.inactive,
	.facetwp-page{
		font-size: 1rem;		
		background: $lightGrey;
		color: $green;
	}
	.prev, .next, .previous{
		display: block;
		float: left;
	}
	.next{
		float: right;
	}
	
	.next,
	.previous,
	.prev {
		background: $green;
		color: white;

		&:hover {
			background: $brownGrey;
			color: white;
		}

	}

	// Hide all numbered items in pagination from smaller displays.
	@include breakpoint(max-width 600px) {

		.facetwp-page {
			&:not(.next) {
				&:not(.previous) {
					display: none;
				}
			}
		}
		.dots {
			display: none;
		}		

	
	}
}

.facetwp-pager{
		border-top: 0;
		border-bottom: 1px solid $lightGrey;
		padding-bottom: 1.5rem;
		margin-bottom: 1.5rem;
	@include breakpoint($medium){
		border: 0;
	}	
}


footer.page{
		text-align: center;
		margin: 3rem 0 0 0;
		padding: 0 0 49% 0;
		background: url('img/footerWave.svg') no-repeat right bottom;
		background-size: 100%; 
		overflow: hidden;
		background-position: right bottom;
    @include breakpoint($large){
    	padding-bottom: 2rem;
    	background-size: 50%;    
		background-position: 120% bottom;
    }
    @include breakpoint(1200px){
		background-size: 50%;    
		background-position: right bottom;
    }
	h2.contactUsBanner{
		background: $brownGrey;
		text-align: center;
		padding: ($gutter*1.5) 0;

	}

	h4{
		margin: 0 0 .25rem 0;
	}


	// social icons - used in footer
	.socialIcons{
		display:block;
		margin: 2rem 0;	
		a{
			display: inline-block !important;
			margin: 0 .2rem !important;
			line-height: 0 !important;
		}
		img{
			width: 2rem !important;
		}

	}
	
	.footerLinks ul a, .citeCredit a{
			color: $bodyTextColor;
		&:hover{
			color: darken($hotPink, 10%);;
		}
	}

    @include breakpoint($medium) {
    	text-align: left;
		.footerLinks, .worldHabitat, .citeCredit, .copyright{
			float: left;
			width: 50%;
			padding-right: 1rem;
			max-width: 17rem;
		}
		.footerLinks ul{
			margin: 0 0 3rem 0;
		}
		.newsletterSignup{
			margin-bottom: 2.5rem;
			white-space: nowrap;
			a{
				font-size: 21px;
				font-size: 1.313rem;
				font-weight: bold;
			}
			a:hover{
				color: darken($hotPink, 10%);;
			}
		}
		.citeCredit{
			max-width: 22rem;
		}
		.copyright.clear, .citeCredit.clear{
			clear: left;
		}
	}

	.disclaimer{
		float: left;
		clear: left;
		margin-top: 1rem;
		@include breakpoint($large) {
			max-width: 50%;
		}
	}
}

// WHA overides
.whaPage footer.page{
	background-image: url('img/footerWaveWHA.svg');

	.newsletterSignup a{
		color: $orange;
		&:hover{
				color: darken($orange, 6%);
		}
	}
	
	h2.contactUsBanner{
		background: $orange;
		a.button{
			background-color: $brownGrey;
			color: #fff;
			&:hover{
				background-color: darken($brownGrey, 6%);
			}
		}
	}
}