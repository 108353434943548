.imageBanner{
		position: relative;
		@include clearfix();
    	// /margin-bottom: -1rem;

		& > div{
			position: relative;
			background-size: cover;
			background-position: center center;
			background-repeat: no-repeat;
			width:0;
			height: 245px;		
			background-color: $lightGrey;
			width: 100%;
		}
	@include breakpoint($large) {
		& > div{
			height: 310px;
		}
	}	
	@include breakpoint($max) {
		& > div{
			height: 500px;
		}
	}		
}