nav.subNav{   
    background: $lightGrey;  
}
aside.leftSideBar{
  
  margin-bottom: 3.250rem; 
}

aside.rightSideBar{      
  .facetwp-facet{
    max-height: 30rem;
    overflow: auto;
    border-bottom: 1px solid $midGrey;
    padding: 0 0 1rem 0;
    margin: 0 0 1rem 0;
    color: $darkGrey;
    & div:hover{
      text-decoration: underline;
    }
  }

}


@at-root .no-js  .facet{// hide facetwp filtering if no JS available
  display: none;
}
.facet{

  .facetwp-checkbox{
    background: url(img/facet-notick.png) 0 .2rem no-repeat;
    background-size: 14px 14px;
    margin-bottom: 8px;
  }
  .facetwp-checkbox.checked {
      background-image: url(img/facet-tick.png);
  }

}

input.facetwp-search{
  background-color: transparent;
  color: $brownGrey;

  &::placeholder {
    color: $brownGrey;
  }
}

.facetwp-btn{
  opacity: 1 !important;

  &:before {
    background: url("img/svgLoader.php?img=icon-search.svg&fill=%2300bfb3") no-repeat center center !important;
    background-size: 1rem !important;
    width: 1.8rem !important;
    height: 1.2rem !important;
  }
}


.facetwp-reset{
  background: $brownGrey;
  color: #fff;
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;
}




/*! Based on responsive-nav.js 1.0.39 by @viljamis */


#subMenuNavButton{
  	background: $hotPink;
    color: #fff;
    font-weight: bold;
  	padding: 1rem;
    line-height: 1.8;
  	width: 100%;
    display: block;
    text-align: left;
    &:after{
      content: " ";
      background: url('img/svgLoader.php?img=icon-arrow.svg&fill=%23ffffff') no-repeat;
      background-size: cover;
      transform: rotate(90deg) translateX(.3rem);
      margin: 0 0 0 .5rem;
      width: 1.2rem;
      height: 1.2rem;
      display: block;
      float: right;
    }    
    &:hover{
      background: darken($navLinkBkgColour, 6%);
    }
  @include breakpoint($medium) { 
    display: none;
  }
}



/* Defaults and No JS/MQ version */
nav.subNav{
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  transform: translateY(-1rem);

  ul {

    width: 100%;
    display: block;
    list-style: none;  
    margin: 0 0 ($navVerticalPadding*2) 0;
  }

  a, .title{
    display: block;
    background: $navLinkBkgColour;
    border-bottom: 1px solid darken($navLinkBkgColour, 8%);
    color: $navLinkColour;
    padding: $navVerticalPadding $navHorizontalPadding;
    font-weight: bold;
  }
  a:hover{
    background: darken($navLinkBkgColour, 6%);
    color: $navLinkColour;
  }
  & > ul > li:last-child a{ 
    border-bottom: 0;
  }

  // current page highlight
  li.current_page_item, { 
    & > a{
      background: $navLinkCurrentBkgColour;
      color: $navLinkCurrentColour !important;
      position: relative;
      padding-right: 1.9rem;
      border-bottom: 0;
    }
    & > a:after {
      content: " ";
      background: url('img/svgLoader.php?img=icon-arrow.svg&fill=%23ffffff') no-repeat;
      background-size: cover;
      width: .7rem;
      height: .7rem;
      position: absolute;
      top: 39%;
      right: 1rem;
    }
  }     

  .title{
  	color: $brownGrey;
  	border-bottom-color: transparent;
  	font-weight: normal;
  }
  @at-root .js nav.subNav .title{
    display: none !important; 
  }
  .current_page_ancestor > ul.children, .current_page_item > ul.children{
    display: block;
  }
  ul.children {
    margin: 0;
    display: none;
    padding-bottom: .5rem;
    border-bottom: 1px solid darken($navLinkBkgColour, 8%);
    line-height: 1.4;
    a{
      padding: .5rem 2rem .5rem 2rem;
      color: $navLinkColour;
      border: 0;
      font-size: 0.95rem;
      font-weight: normal;   
    }
    a:before{
      content: "\2013";
      position: absolute;
      margin-left: -1rem;
    }
    ul.children{
      margin: 0;
    }
    ul.children a{
      padding-left: 3rem;
    }

    ul.children ul.children a{
      padding-left: 4rem;
    }

  }  

  /* hide parts of the menu - mainNav is displaying most page levels in the burger menu */
 /* ul.top > li:not(.current_page_ancestor):not(.current_page_item), ul.top > li > a{
    display: none;
  } */

}



/* Nav button - injected by JS */

.js nav.subNav.closed{
    display:none;
  @include breakpoint($medium) {
    display:block;
  }  
}

.js nav.subNav.opened {
    max-height: 9999px;
}



// Hamburger menu
.js nav.subNav{
  margin: 0 (-$gutter);

  ul{
    margin: 0;
  }
 

}


// Non hamburger menu
@include breakpoint($medium) {

  nav.subNav{
    margin: 0 !important;
    padding-top: .25rem;

    /* show a more complete menu  since mainNav is now just show top level pages */
    ul.top > li:not(.current_page_ancestor):not(.current_page_item), ul.top > li > a{
      display: block;
    }
    
/*  
    ul.children{ // hide all child pages
      display: none;
    }
    li.current_page_item ul.children, li.current_page_ancestor ul.children{ // show child pages of the section we're in
      display: block;
    }
  */

  }
  .js nav.subNav .title{
    display: block !important; 
  }

}



// variation for WHA sectiom

.whaPage nav.subNav{

  a:hover{
    color: $orange;
  }
  // menu nav highlight class 
  @at-root body.single-awards-cpt nav.subNav li.page-item-191, 
  .single-awards-cpt nav.subNav li.page-item-4783, 
  .single-awards-cpt nav.subNav li.page-item-4784
  {

    & > a{
      background: $orange;
      color: $brownGrey !important;
    }
  }     

}

// this had to be repeated as it dodn't work with the above @at-root
@at-root .whaPage nav.subNav li.current_page_item > a{
    background: $orange;
    color: $brownGrey !important;
}     


.whaPage #subMenuNavButton{
  background-color: $green;
}



