/*! Based on responsive-nav.js 1.0.39 by @viljamis */

//variable defaults
$navVerticalPadding     : 20px !default;    // padding above and below each menu item
$navHorizontalPadding   : 20px !default;    // padding left and right of each menu item 

$burgerBkgColour        : pink !default;    // burge menu button colour
$navLinkBkgColour       : grey !default;    // button bkg colour
$navLinkColour          : white !default;   // link text colour
$navLinkCurrentBkgColour: red !default;     // currently page/section bkg
$navLinkCurrentColour   : white !default;   // currently page/section text

/* Defaults and No JS/MQ version */
nav.mainNav{
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;

  ul {
    width: 100%;
    display: block;
    list-style: none;  
    margin: 0 0 ($navVerticalPadding*2) 0;
  }
  ul.children{
    margin: 0;
    list-style: none;
    a{
      padding-left: $gutter*2;
    }
    a:before{
      content: "\2014";
      margin-right:.5rem;

    }

  }
  a{
    display: block;
    border-bottom: 1px solid darken($hotPink, 3.5%);
    margin: $navVerticalPadding $navHorizontalPadding;
    padding: 0 0 $navVerticalPadding 0;
    color: #fff;
    font-weight: bold;

    &:hover{
      color: #fff;
    }
  }
  

  @at-root body.whaPage nav.mainNav li.page-item-144 > a,  // EN
  nav.mainNav li.current_page_item > a,
  nav.mainNav ul.children li.current_page_ancestor > a{ // menu nav highlight class
    font-weight: bold;
  }     
  @at-root body.whaPage nav.mainNav li.page-item-4624 > a{ // ES -causes SASS error if part of above rule.
    font-weight: bold;
  }     
  @at-root body.whaPage nav.mainNav li.page-item-4623 > a{ // FR
    font-weight: bold;
  }       

}

.opened {
    max-height: 9999px;
}



/* Nav button - injected by JS */

.nav-toggle {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: none;
  text-indent: -999px;
  position: absolute;
  top: 1rem;
  right:$gutter;
  overflow: hidden;
  width: 34px;
  height: 27px;
  margin:0;
  background: url("img/svgLoader.php?img=burgerMenu.svg&fill=%23e2407b") center center no-repeat;
  z-index: 99;
}
.whaPage .nav-toggle{
  background-image: url("img/svgLoader.php?img=burgerMenu.svg&fill=%23ff8200");
}
.nav-toggle.snipcartActive{
  top: 3.8rem;
}
.no-svg .nav-toggle{
  background-image: url("img/burgerMenu.png");
}

.nav-toggle.active {
  background-image: url("img/svgLoader.php?img=burgerClose.svg");
  top: 1.4rem;
  position:absolute;
}
.no-svg .nav-toggle.active{
  background-image:url("img/burgerMenu.png");
}

.js nav.mainNav.closed{
    display:none;
  @include breakpoint($nav) {
    display:block;
  }  
}

// Hamburger menu (opened)
.js nav.mainNav.opened{

  & > ul:first-of-type{
    position: absolute;
    background: $burgerBkgColour;
    top:0; left: 0; right: 0; bottom: 0;
    overflow:auto;
    overflow-x: hidden;
    z-index: 98;
    padding-top: 5rem; // set the position where the menu items begin when menu open
  }
  &.snipcartActive ul:first-of-type{  // move down to make room for snipcart button
    top: 2.8rem;
  }
  ul{
    margin: 0;
  }
  ul.children{
    display: none;
  }


}


// prevents page scrolling when navigation is open (only scrolls as much as the navigation needs)
.js-main-nav-active, .js-main-nav-active body{
    overflow: auto;
    height: 100%;
    width:100%;
    position: static !important;
  @include breakpoint($nav) {
    overflow:visible;
  }
}


// Desktop menu - below logo
@include breakpoint($nav) {

  .js nav.mainNav.opened > ul:first-of-type{
    background: none;
    position: static;
    padding-top: 0;
  }
  .js nav.mainNav.opened a{
    color: orange;
  }
  .nav-toggle{ display:none;}

  nav.mainNav{
      @include clearfix();
      position: relative !important;
      max-width: $pageWidth;
      padding: 0 $gutter 0 .25rem;
      margin: auto;

    &:before{ // grey line below logo
      content: "";
      height: 1px;
      background-color: $midGrey;
      width: 100%;
      display: block;
      margin: 0 0 1.5rem 0;
      margin: 0 0 2vw 0;
    }
    ul{
      margin: 0;
    }
    & > ul > li{   
      display:inline-block;
      width:auto;
      padding-bottom: 1.5rem;
      padding-bottom: 2vw;
    }
    & > ul > li > a:after{
      content: " ";
      height: 5px;
      display: block;
    }
    a{
      color: $brownGrey;
      border: 0; 
      background: none;
      display: block;
      padding: .15rem .75rem 0 .75rem;
      margin: 0;
      //font sizes set in Typography
    }
    li:last-child a, li.last a{
      // remove margin from last generated page list AND last item in UL
      margin-right:0;
    }
    a:hover, a:active{
      background: none;
      color: $brownGrey;
    }
    li:hover a:after{
      background-color: $hotPink;
    }
    li:hover ul.children{ // trigger drop downs!
      display: block;
    }

    & > ul > li.current_page_ancestor > a,
    & > ul > li.current_page_item > a{ // menu nav highlight class
      @include breakpoint($nav) {    // shouldn't need the MQ here (it's already in one) but looks like the @at-root thing destroys it!
        background: none;
        border: 2px solid $hotPink;
        padding-top: .25rem; //fudge
        &:after{ background-color: transparent !important; }
      }
    }     
    // drop down styles
    ul.children{
      display: none;
      background: $hotPink;
      opacity: .98;
      width: 25%;
      position: absolute;
      margin-top: 1rem;
      z-index: 99;
      text-align: left;
     // display: block;
      padding: 0;       
      min-width: 8rem; 
      max-width: 325px;      
      li{
        border-bottom: 1px solid lighten($hotPink,10%) ;
       // white-space: nowrap;
        float: none;
        display: block;
        margin: 0;
      
        &.current_page_item a{
          border-top: 0;
          padding-top: 0.3rem;
        }
      }

      a{
        color: #fff;
        padding: .6em 1rem;
      }
      a:hover{
        border-top-color: transparent;
        background-color: $red;
        color: #fff;
      }      
      a:before{
        content: none;
      }
    }
  }
  .js nav.mainNav.closed {
      max-height: none;
  }

}

// Desktop menu - big
@include breakpoint(1220px) {
  body.lang-en nav.mainNav{
    max-width: $pageWidth;
    padding: 0 $gutter;
    margin: auto;
    & > ul{
      margin: 0;
      position: absolute;
      right: $gutter;
      top: -5.25rem;
      width: auto;
    }
    & > ul > li{
        padding-bottom: 1.8rem;
    }
  }
}



.lt-ie8 nav.mainNav{ /* Hack to clear menu float in IE<7 */
  float: left; width: 100%;
}
.lt-ie8 nav.mainNav li{ /* Hack to enable inline block in IE<7 */
    *display: inline;
    zoom: 1;
}


// Desktop menu - left aligned
@include breakpoint(1220px) {
  nav.mainNav{
    ul{
      text-align: center;
    }
      &:before{ content: none; }
  }
}




// WHA section overides
.whaPage nav.mainNav .page-item-144 > a, // en
.whaPage nav.mainNav .page-item-4624 > a, // es
.whaPage nav.mainNav .page-item-4623 > a{ //fr
  @include breakpoint($nav) {
    border-color: orange;
  }
 } 



  .children li.current_page_item > a{
    border: none;
    &:hover{
      color: $orange; 
    }    
  }




nav.mainNav li.page-item-144:hover > a,
nav.mainNav li.page-item-4624:hover > a,
nav.mainNav li.page-item-4623:hover > a{
  @include breakpoint($nav) {  
    color: $orange;
    &:after{
        background-color: $orange;
    }
  }
}



li.page-item-144,
li.page-item-4624,
li.page-item-4623{

 
  // Desktop menu
  @include breakpoint($nav) {
   // drop down styles
    ul.children{
      background: $orange;
        a{
          color: $brownGrey;
        }
        li{
          border-color: darken($orange, 6%);
        }
        a:hover{
          color: $brownGrey;
          background-color: darken($orange, 6%);
        }      
    }
  }


}




