.searchForm{
		display: block;
		overflow: hidden;
		margin: $gutter (-$gutter) 0 (-$gutter);
		padding: $gutter;
		background: lighten($lightGrey, 5%);
		clear: both;
	    margin: 0rem 0rem 1rem 0rem;
	    padding-top: 0rem;
		background-color: transparent;
		color: white;
}

// Hide the search form in main nav when not in burger mode
.mainNav .searchForm{
    @include breakpoint($nav){	
    	display: none;
    }		
}

// search form is hidden in footer until burger is activated
footer .searchForm{
	display: none;
}



// Styles for our search results page
.page-template-search{

	.searchWrap{
		max-width: 600px;
		margin: 2rem auto 0 auto;
		padding: 1rem 1rem .25rem 1rem;
		background-color: $orange;
	}


	.search-results__title {

	}

	.search-results__intro {
    	margin-bottom: 4rem;
	}

	.search-results__subtitle {
    	border-bottom: 1px solid $midGrey;
	    padding-bottom: 0.5rem;
	}

	.search-results__section {
		margin-bottom: 2.5rem;
	}

	.search-results__more {
		&:after {
			content: "";
			background: url("img/svgLoader.php?img=icon-arrow.svg&fill=%23e2407b") center center no-repeat;
			width: 10px;
			height: 10px;
			background-size: contain;
			padding-left: 11px;
			vertical-align: top;
			position: relative;
			top: 1px;
			margin-left: 3px;
		}

	}

	.search-results__item {
		margin-bottom: 1.5rem;

		&__title {

			margin-left: 20px;

			&:before {
				content: "";
				background: url("img/svgLoader.php?img=icon-arrow.svg&fill=%23e2407b") center center no-repeat;
				width: 10px;
				height: 10px;
				background-size: contain;
				vertical-align: top;
			    position: relative;
    			top: 1px;
				left: -20px;
				display: inline;
				margin-right: -15px;
				padding-right: 15px;
			}
		}

		&__excerpt {
			margin-left: 20px;
		}

	}

	// Pagination
	.navigation {
		margin-bottom: 15px;
		padding: 10px 15px;
		border-top: 1px solid $midGrey;
	    padding-top: 1.5rem;

		@include clearfix;

		.itemcount {
			text-align: center;
		}

		.wp-paginate {
			list-style: none;
			@include clearfix;
			width: 100%;
			margin: 0rem;
			padding: 0rem;
		    font-size: 1rem;
	        text-align: center;
		}

		.hidden-sm {
			display: none;
			list-style: none;
		}

		&__prev {
			float: left;
			text-align: left; 

			a {
				display: block;
			    padding: .75rem;
		        background: $green;
    			color: white;
    			font-weight: bold;
			}
		}

		&__next {
			float: right;

			a {
				display: block;
			    padding: .75rem;
		        background: $green;
    			color: white;
    			font-weight: bold;
			}	
		}

	}

}

@include breakpoint(768px) {

	.page-template-search{

		.navigation {

			.hidden-sm {
				display: inline-block;
			}

			&__prev,
			&__next {
				display: inline-block;

				a {
					display: inline-block;
				}
			}

			&__prev {
				margin-right: 0.25rem;
			}

			&__next {
				margin-left: 0.25rem;
			}

			.page {
				display: inline-block;
				background: $midGrey;
		    	padding: 0rem;
				font-weight: bold;
			    margin: 0rem 0.25rem;

				a, span{
					display: inline-block;
					color: $green;
					padding: 0.75em;
					
				}

				&.current {
					background: $green;

					span {
						color: #fff;
					}
				}

			}

		}

	}

}

