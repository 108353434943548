.single-publications-cpt{ // publications post page

	.buyHardCopy, .downloadForFree{
		width: 100%;
	}
	.downloadForFree{
		span{
			display: block;
			font-size: 1rem;
			font-weight: normal;
			color: #fff;
		}
	}
	.buyHardCopy{
		background: $green;
		font-weight: normal;
		span{
			display: block;
			font-weight: bold;
			color: #fff;
		}
	}

	// Styles for publication download modal form
	.publicationNewsletterSignup {
		visibility: visible;
		opacity: 1;
		height: auto;

		&.hide {
			visibility: hidden;
			opacity: 0;
			height: 0;
			transition: all 0.3s ease-in-out;

			* {
				height: 0;
			}
		}

		input[type="email"],
		input[type="submit"] {
			border: 0px none;
			box-sizing: border-box;
			width: 100%;
			-webkit-appearance: none;
			border-radius: 0;
		}

		input[type="email"] {
		    margin-bottom: 0.5rem;
		
			&:focus {
				background-color: $lightGrey;
			}
		}

		&-vaildation {
			color: red;
			clear: both;
			margin-top: 1rem;
			visibility: hidden;
			opacity: 0;
    		height: 0;
    		backface-visibility: hidden;
			transition: all 0.35s;

			&.error {
				visibility: visible;
				opacity: 1;
				height: auto;
				transition: all 0.35s;
			}

		}


		&-success {
			visibility: hidden;
			opacity: 0;
			height: 0;
			font-style: italic;
			margin-bottom: 0;

			&.show {
				visibility: visible;
				opacity: 1;
				height: auto;
				transition: all 0.3s ease-in-out;
			}

		}

		@include breakpoint($nav) {
			@include clearfix;

			 input[type="email"] {
				float: left;
				width: 72%;
				margin-bottom: 0rem;
			 }

			 input[type="submit"] {
				float: left;
				width: 25%;
			 }
    	}    

	}

}


.js .page-template-archive-publications-cpt{
	aside.rightSideBar .facetwp-facet{
	  max-height: 45rem;
	}
}