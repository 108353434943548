.latestAndRelated{
  clear: left;
  @include clearfix();
  float: left;
  width: 100%;
  //margin: 0 0 -1.5rem 0;
  margin: 0 0 2rem 0;

  h3.title{
    p{
      margin-left: 1rem;
      display: inline-block;
    }

  }


  // for news posts

  // layout 4 is new for World Habitat and I've had to fudge the grid to get the gutters to work correctly
  &.newsLayout4{
      @include breakpoint($small) {         
          margin-left: -0.5rem;
          margin-right: -0.5rem;
          width: auto;
          
          & > .title{
            margin-left: .5rem;
            margin-right: .5rem;
          }
          .entry{
            float: left;
            width: 50%;
            border: none;
            padding: 0 .5rem;
          }
      }
      @include breakpoint($large) {  
        .entry{
            width: 25%;
        }
      }
  }


  @include breakpoint($small) {   
    &.newsLayout2, &.newsLayout3{
        .entry { 
          float: left;
          // all this weird padding adds up to 2rem gutters
          padding: 0 0.625rem; 
          &:first-of-type{
            padding: 0 1.25rem 0 0;
          }
          &:last-of-type{
            padding: 0 0 0 1.25rem;
          }
        }
    }

    &.newsLayout3 .entry{
        width: 33.3%;
        border: none;
    }
    &.newsLayout2 .entry{
        width: 50%;
        border: none;
    }    

  }      
  // for Awards and Publications (used on Awards post detail)
  @include breakpoint($medium) {  
    &.awardsLayout{
      float: left;
      width: 48%;
    }
    &.publicationsLayout{
      float: right;
      width: 48%;
      clear: none;
    }
  }
}


// Programme Detail template features full width tabs and needs a different layout for them
.page-template-page-programme-detail .latestAndRelated.awardsLayout,
.page-template-page-programme-detail .latestAndRelated.publicationsLayout{
     float: none;
     width: 100%;
  
  @include breakpoint($medium) {  
    .textEntry{
      width: 47%;
      border-bottom: 1px solid $midGrey;
      min-height: 5rem;
      padding-bottom: 1rem;
      margin-bottom: 1rem;
    }
    .textEntry:nth-child(odd){
      float: left;
      clear: left;
    }
    .textEntry:nth-child(even){
      float: right;
    }
  }
  @include breakpoint($large) {  
    .awardsLayout, .publicationsLayout{
      padding: 1rem 1.5rem;
    }
  }
  @include breakpoint($xlarge) {    
    .entry{
      width: 47%;
      min-height: 5rem;
    }
    .entry:nth-child(odd){
      float: left;
      clear: left;      
    }
    .entry:nth-child(even){
      float: right;
    }
    .entry:nth-last-child(2){ // 2 col layout needs last 2 .entry borders removing
      //border-bottom: 0;
    }
  }  
}
