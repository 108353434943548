.page-template-page-about-front{
	.bshfInNumbers{
		margin: 0 -0.7rem 3rem -0.7rem;
		@include clearfix;
		h2{ margin-left: 0.5rem;}

		.factBox{
				text-align: center;
				font-weight: bold;
				padding: 13.5rem .5rem 3rem .5rem;
				margin: 0 0 1rem 0;
				position: relative;
 				
 				// bit of an odd way to apply to fill to boxes, rqd due to how
 				// gutters are applied with padding not margin
				&:before{
					z-index: -1;
					background-color: $lightGrey;
					background-repeat: no-repeat;
					background-position: center top;
					background-size: 102%; // make sure swirl bkg fits upto/beyond box edges
					border: 1px solid darken($lightGrey, 10%);
					position: absolute;
					top: 0; right: .5rem; bottom: 0; left: .5rem;
					content: " ";
				}
      		@include breakpoint($small){  			
      			float: left;
      			width: 50%;
      			&:first-of-type{
					margin-left: 0;
				}
				&:last-of-type{
					margin-left: 0;
				}
      		}
      		@include breakpoint($medium){  			
      			width: 25%;
      		}

      		img{
      			display: inline-block;
      			width: 100%;
      			margin: 0 0 .75rem 0;
      		}
      		strong{
	      			font-weight: bold;
	      			display: block;
	      			font-size: 5.8rem;
	      			letter-spacing: -0.1rem;
	      			line-height: 3.8rem;
	      			margin: 0 0 2.4rem 0;
				@include breakpoint($medium $xlarge){  			
					// number gets too big for box aound these sizes - needs a custom size
					font-size: 4rem;
      				font-size: 8.5vw;
      			}
      		}
      		hr{
			    width: 30%;
			    margin: 0 auto 2rem auto;
			    min-width: 100px;
      		}
      		span{
      			display: block;
      			margin: 0 1rem;
      		}
		}	
	    .factBox.logo{
	    	&:before{
	  			background-image: url('img/whaLogo.svg');
	  			background-size: 70%;
	  			background-position: center 3rem;
  			}
  		}	
	    .factBox.orange{
	    	strong{ color: $orange;}
	    	&:before{
	  			background-image: url('img/orangeSwirls.svg');
	  		}	  		
	    }
	    .factBox.pink{
	    	strong{ color: $hotPink; }
	    	&:before{
	  			background-image: url('img/pinkSwirls.svg');
	  		}	  		
	    }
	    .factBox.green{
	    	strong{ color: $green; }
	    	&:before{
	  			background-image: url('img/greenSwirls.svg');
	  		}	  		  		  		
		}
	}
}