// Forms are a bit of a mess in this poject. We should have stripped out all of the Gravity forms styles
// and stared from scratch. Instead we've hacked them, creating a lot of duplicate code and making it
// difficult to manage... Sorry!

input, textarea, select{font-family:inherit;}

textarea{
  padding:.25em;

}

input[type='email'],
input[type='tel'],
input[type='url'],
input[type='number'],
input[type='date'],
input[type='text'],
input[type='search'], 
textarea, 
select{
  border: 1px solid $midGrey; 
  color: darken($midGrey, 50%);
  background: $lightGrey;  
}
input::placeholder {
  color: darken($midGrey, 15%);
  opacity: 1;
  line-height: normal;
  font-size: 1em;
  padding: 0;
}
input[type='text']:focus, textarea:focus{
// background-color: #fff; 
}
input, textarea{
  font-size: 1rem;
  padding: .75rem !important;
  line-height: normal;
  border: 0;
  width: 99.5%;
}
label{
  font-weight: bold !important;
  margin: 0 0 .5rem 0 !important;
  //display: block !important; 
  font-size: 1rem;
  line-height: 1.5;
}
select{
  height: 3rem;
  color: $bodyTextColor;
  font-family: inherit;
  text-indent: .3rem;
}
.search{
  position: relative;
  input[type='text']{
    vertical-align: middle;
    width: 90%;
    border-right: 0;   
    padding-right: 1px !important; 
  }
  input::placeholder {
    line-height: 1.5;
  }
  input[type='submit']{
    color: transparent;
    line-height: 1;
    border: 1px solid $midGrey;
    border-left: 0;
    background: url("img/svgLoader.php?img=icon-search.svg&fill=%23919191") no-repeat center center $lightGrey;
    background-size: 1.36rem;
    width: 10%;
    min-width: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
  }
}


// RESET all the G Forms crap...
.gform_wrapper li.gfield.gfield_error.gfield_contains_required div.gfield_description, .gform_wrapper li.gfield.gfield_error.gfield_contains_required div.ginput_container, .gform_wrapper li.gfield.gfield_error.gfield_contains_required fieldset legend.gfield_label, .gform_wrapper li.gfield.gfield_error.gfield_contains_required label.gfield_label{
  margin-left: 0 !important;
}

.gform_wrapper li.gfield.gfield_error.gfield_contains_required div.ginput_container, .gform_wrapper li.gfield.gfield_error.gfield_contains_required label.gfield_label{
  margin-top: 0;
}
.gform_wrapper .gfield_date_day label, .gform_wrapper .gfield_date_month label, .gform_wrapper .gfield_date_year label, .gform_wrapper .gfield_time_ampm label, .gform_wrapper .gfield_time_hour label, .gform_wrapper .gfield_time_minute label, .gform_wrapper .ginput_complex label, .gform_wrapper .instruction{
  margin: 0 0 1em 0;
  padding-top: .2rem;
  color: $bodyTextColor;
  font-size: .889rem; // matched to small in _typography
  line-height: 1.3rem;  
}
.gform_wrapper.gf_browser_chrome .ginput_complex .ginput_right select, .gform_wrapper.gf_browser_chrome .ginput_complex select{
  margin-bottom: 0 !important;
}
.gform_wrapper .top_label .gfield_description{
  padding: 0;
}
.gform_wrapper h1, .gform_wrapper h2, .gform_wrapper h3{
  font-weight: bold !important;
}
// Robs G forms


.gform_wrapper {
  max-width: 100%;

  form:before {
        display: none;
  }
  ul {
    /*margin: 1em 0 1em 0 !important;
    list-style-position: inside !important;
    padding-left: 20px !important;*/
  }
  li{
    margin-bottom: 1em;
  }

  li.gfield.gf_right_half textarea,
  li.gfield.gf_left_half input.large {
    width: 100%;
    border: 1px solid $midGrey;
  }
  input[type="submit"], input[type="button"] {
    font-size: 1.3rem !important;
  }

  .gfield_html {
    ul {
      margin: 1em 0 2em 0 !important;
      list-style-position: inside !important;
      padding-left: 3rem !important;
    }
  }

  .gform_footer {
      margin: 0em;
      padding: 0em;
  }
  .gform_page_footer {
      border-top: 1px solid #ccc !important;

      .gform_next_button, .gform_button {
        float: right;
        width: 100px;
        margin-right: 0 !important;
      }

      .gform_previous_button {
        float: left;
        width: 100px;
      }

      .gform_save_link {
        clear: both;
        float: right;
        margin-top: 10px;
      }
  }

  .gf_progressbar {
    background-image: url("img/gf-percentbar-bg.png");

    .gf_progressbar_percentage {
      background-image: url("img/gf-percentbar-green.png");
    }
  }

}


.application-form_wrapper.gform_wrapper {
  
    .gf_progressbar{
        background-image: none;
        background-color: $lightGrey;
    }

    .percentbar_blue {
        background-image: none;
        background-color: $green;
    }
}


// Align bullet points for application form html form field
.application-form_wrapper.gform_wrapper div.gform_body ul.gform_fields li.gfield.gfield_html ul, 
.application-form_wrapper.gform_wrapper form div.gform_body ul.gform_fields li.gfield.gfield_html ul {
  padding-left: 20px;
}

.gform_wrapper h2.gsection_title{
  font-size: 1.3rem !important;
}

// word count
.gp-word-count-label{
  font-size: .889rem !important;
  margin-top: 0 !important;
  text-align: right;
}



.address_zip {
  margin-left: 0 !important;
}

// Fix layout for address field on second step of application form
.gform_wrapper .address-single-line .ginput_complex {

    .ginput_right.address_zip {
        width: 100%;
        margin-top: 1em;
    }

    @include breakpoint($medium) {


      .ginput_full {
        float: left;
        width: 47.5%;
      }

      .ginput_right.address_zip {
        float: right;
        width: 47.5%;
        margin-top: 0em;
      }

    }

}
body .gform_wrapper.gf_browser_chrome .gfield.country-select {

    select {
      width: 97.5% !important;
    }  

    @include breakpoint($medium) {
      width: 47.5%;
    }
}

.gform_wrapper .currency {
  position: relative;
  div:before{
    content: "\0024";
    position: absolute;
    font-size: 1.1rem;
    top: 2.4rem; top:50%;
    left: .5rem;
  }
  input{
    text-indent: .5rem !important;
  }
}

.gform_wrapper ul.gfield_checkbox li input[type=checkbox], .gform_wrapper ul.gfield_radio li input[type=radio]{
  margin-top: -6px !important;
}