.page-template-page-contact, .page-template-page-full-width{
	
	.page-contact-us {

		@include clearfix;

		&__top {
			@include clearfix;
		}
		
		.breadcrumbs {
			margin-bottom: 20px;
		}

		.leftSideBar {
			background: transparent;
		}

		#gform_fields_2 {
			@include clearfix;
		}

		&__location {
			border-top: 1px solid $midGrey;
		    padding: 1rem 0rem;
		    @include clearfix;
		
			#gMap{
				width: 100%;
				height: 33rem;
			}

			.map-container {

				.mobile-infobox {
					position: absolute;

					.info-window {
						width: 100%;

						&__image {
							display: none;
						}

						&__details {
					    	width: 100%;
					    	margin: 10px;
						}

						&__year {
							font-size: 0.8em;
						}

					}
				}

				.info-window {

					&__name {
						color: $green;
					}

				}
			}

		}

		@include breakpoint($small) {

			&__location {

				padding: 2rem 0rem 3rem 0rem;

				.map-container .mobile-infobox .info-window {
					width: 420px;

					&__image{
						display: block;
						width: 24%;
						padding-bottom: 24%;
					}

					&__details {
						width: 70%;
					    margin-top: 30px;
					}
				}
			}

		}
		
	}

	.gform_wrapper{
		margin: 0 !important;

		form{
			margin-top:.55rem;
			position: relative;
			@include clearfix();
			margin-bottom: 2rem;
		}
		label{
			line-height: 1.4 !important;
		}

		select {
			-webkit-appearance: none;
		 	border-radius:0;
		}
	}

	.gform_wrapper div.validation_error {
		font-size: 100% !important;
		font-weight: normal !important;
		border: 0px none !important;
		padding: 1em 0px 1em 20px !important;
		color: red !important;
	}

	.gform_wrapper li.gfield.gfield_error{
		background-color: transparent !important;
		border: 0px none !important;
	}

	.gform_wrapper .gfield_description {
		width: 100% !important;
		display: block;
	}

	.gform_wrapper .validation_message {
		position: relative;
		margin-top: 0px !important;
		padding-top: 0px !important;
		color: red !important;
	}

	.gform_wrapper .gfield_error input, .gform_wrapper .gfield_error textarea {
		background-color: $lightGrey;
	}


	@include breakpoint(max-width ($medium - 1 )) {

		.gform_wrapper div.ginput_complex.ginput_container span.name_first,
		.gform_wrapper div.ginput_complex.ginput_container span.name_last {
			width: 100%;
			padding-right: 0px;

			input {
				width: 98.5%;
			}

		}

	}

	.gform_footer {	
	    margin: 0 !important;
	    padding: 0 !important;	

	    input[type=submit] {
			-webkit-appearance: none;
		 	border-radius:0;
	    }
	}


	@include breakpoint($medium) {

		.map-container {
			display: block !important;

			&__mobile-close {
				display: none;
			}
		}

		// Hide mobile map trigger
		.mobile-map-button {
			display: none;
		}

	}


	// Remove styles for GF ready classes
	@include breakpoint(max-width 1100px) {

		.page-contact-us .gform_wrapper .top_label .gfield_error .ginput_container{
			max-width: 100% !important;
		}

		// As GF uses important tags, force them off by navigating higher up the DOM tree
		.page-contact-us .gform_wrapper .top_label li.gfield.gfield_error.gf_left_half, 
		.page-contact-us .gform_wrapper .top_label li.gfield.gfield_error.gf_right_half {
			width: 101.75% !important;
			margin-right: 0 !important;
		}

		.page-contact-us .gform_wrapper .top_label li.gfield.gf_right_half,
		.page-contact-us .gform_wrapper .top_label li.gfield.gf_left_half{
			width: 101.75% !important;
			margin-right: 0 !important;
		}

	}


	@include breakpoint(1100px) {
		
		.page-contact-us {
			&__top {

				@include clearfix;

				.gform_wrapper .top_label li.gfield.gfield_error.gf_left_half, 
				.gform_wrapper .top_label li.gfield.gfield_error.gf_right_half,
				.gform_wrapper .top_label li.gfield.gf_right_half {
					width: 49.8% !important;
				}

				.gform_wrapper .top_label li.gfield.gf_left_half{
					float: left;
			    	margin-right: 1.5%;
		    	    margin-bottom: 1em;
					width: 48.5% !important;		    	    
		    	    
				}

				li.gfield.gf_right_half textarea{
					width: 100%;
				    height: 16.95rem;
				}
				li.gfield.gf_right_half input{
					width: 100.2% !important;
				}
				.gform_footer {
					position: absolute;
				    right: 0.5rem;
				    bottom: 1rem;
				    width: auto;			
				}

			}
		}

	}


	@include breakpoint(1430px) {
		
		.page-contact-us {

			li.gfield.gf_right_half textarea{
			   height: 16.81rem;
			}
		
		}


	}
}