.carousel{
	margin: 1rem 0 2rem 0;
	padding: 3rem 0 0 0;
	background: $lightGrey;
	@include clearfix();

	.flickity, .title{
		  	padding: 0 $gutter;		
			max-width: $pageWidth;
			margin-left: auto;  			
			margin-right: auto;  			
			margin-bottom: 3rem;
	}
	.carouselItem{
	    width: 290px;
	    float: left;
	    margin-right: $gutter;
		a{
			display: block;
			margin: 0 0 .25rem 0;
		}
		p{
			margin: 0 1rem 0 0;
		}
		a p{
			font-weight: bold;
			line-height: 1.35rem;
			margin: .7rem 0 .75rem 0;			
		}
	} 
	@at-root .no-js .carouselItem{
		min-height: 25rem;
	}

	@at-root .carousel.noFlick{
      @include breakpoint($medium){  
        padding-right: 0;
        .carouselItem{
          width: 25%;
          margin-right: 0;
          padding-right: $gutter;
          max-width: 355px;
        }
      }   
  } 

	.carouselItem:last-of-type{
		margin-right: 0;
	}

	h3 , h3 + p{		
		display: inline-block;
		margin-right: 1rem;
		margin-bottom: 0;
	}


	.carouselItem.winner,
	.carouselItem.specialMention{
	  position: relative;
	  &:before{
	      position: absolute;
	      content: url(img/icon-star.svg);
	      background:  #fce952;
	      background: linear-gradient(to bottom, #fce952 0%,#d48c26 100%);
	      padding: 11px 0 0 13px;
	      width: 46px;
	      height: 46px;
	      left: 0rem;
	      top: 0rem;      
	  }
	}
	.carouselItem.specialMention{
	  &:before{
	      background-color:  #cecece;
	      background: url(img/icon-star.svg), linear-gradient(to bottom, #ecebed 0%,#b3b3b3 100%);
	      background-repeat: no-repeat;
	      background-position: center;
	  }
	}	
}



// variation for WHA section

.whaPage .carousel{
	background-color: transparent;
	border-top: 7px solid $orange;

	.carouselItem a{
		color: $bodyTextColor;
		text-decoration: underline;
		&:hover{
			color: $orange;
		}
	}
	.flickity-prev-next-button:hover {
    	background-color: $orange;
	}
}