.page-template-page-wha-front { // WHA front page

	.awardsHomeBanners{
			@include clearfix();
			margin: -1rem 0 2.5rem 0;

		.mainBanner{
				position: relative;
	    		background-position: center;
	    		background-size: cover;	
	    		margin-bottom: .5rem;
			@include breakpoint(850px) {
				margin-bottom: 0;
				border: none;
				width: 63%;
				float: left;
			}	    	
			.text{
					padding: 1rem;
					background-color: $brownGrey;
				@include breakpoint(620px) {
					padding: 0;
					background-color: transparent;
					width: 96%;
					position: absolute;
					bottom: 1.5rem;
					left: 1rem;
				}    	
				@include breakpoint($large) {
					width: 90%;
					position: absolute;
					bottom: 25%;
					bottom: 10%;
					left: 3rem;
				}
				.whaLogo{
						float: right;
					    width: 34%;
					    margin: .5rem 0 0 1rem;
						visibility: visible;
						max-width: 115px;
					@include breakpoint($medium) {
					    margin: 0 0 0 1rem;						
					}					
				}
				h2{ margin: 0; }
			}
			p{
				margin: 0;
			}
			.bannerTitle{
				color: #fff;
				font-weight: bold;
				margin: 0 0 1.5rem 0;
			}
			img{
					display: block;
					width: 100%;
				@include breakpoint(620px) {
					visibility: hidden;	    		
				}    		
			}
		}// end .mainBanner

		.sideBanner.first{
			@include breakpoint($small) {	
				border-right: .25rem solid #fff;
			}
			@include breakpoint(850px) {			
				border-right: none;
			}
		}
		.sideBanner.last{
			@include breakpoint($small) {				
				border-left: .25rem solid #fff;
			}
			@include breakpoint(850px) {			
				border-top: .5rem solid #fff;
				border-left: .5rem solid #fff;
			}
		}
		.sideBanner{
				position: relative;	
				margin-bottom: .5rem;		
			@include breakpoint($small) {
				margin-bottom: 0;
				float: left;
				width: 50%;
			}
			@include breakpoint(850px) {
				border-left: .5rem solid #fff;
				float: right;
				width: 37%;
				margin: 0;
			}
			a{
					display: block;
			    @include breakpoint($medium) {		
			    	background-position: center;
			    	background-size: cover;
			    }				
			}	    	
			a:hover:before {
				content: "";
				display: block;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				background: $imageHover;
				transition: background .3s linear;
			}		
			img{
					display: block;
					width: 100%;
				@include breakpoint($medium) {
					visibility: hidden;	    		
				}    		
			}

			span.title{
					background: $orange;
					color: $bodyTextColor;
					padding: .5rem 	1.5rem;
					line-height: 1.7;
					font-weight: bold;
					display: block;
				@include breakpoint($medium) {
					position: absolute;
					bottom: 1.5rem;					
				}				
			}
			a:hover img{
				opacity: .90;
			}
		} // end .sideBanner
	}

	@include breakpoint($medium) {

		.pageHeader {

			columns: 2;
			column-fill: balance;
    		column-gap: 4rem;
			max-width: none;

			margin: 1.5rem 0rem;

			h1 {
				margin-top: 0rem;
			}
		}

	}

	@include breakpoint($large) {

		.pageHeader { 
			h1 { margin-bottom: 0; }
		}
	
	}
	@include breakpoint($xlarge) {
		.inPartnershipUN {
			margin-top: 2.75rem;
			text-align: right;
			.left{
				float: left;
			}
			img{
				vertical-align: top;
				display: inline-block;
			}
		}
	}

	.peerExchanges {
			background: $brownGrey;
			padding: 2rem $gutter 0 $gutter;		
			margin: 0 0 2rem 0;
			overflow: hidden;
			text-align: center;
			@include breakpoint($medium) {
				overflow: visible;
			}
			
			img{
				display: block;
				width:100%;
				max-width:  445px;
			}
			h2{
				color: #fff;
				max-width: 29rem;
				margin: 1.5rem auto;
			}
			.text{
				margin-bottom: 1rem;
			}
		@include breakpoint($medium) {
			text-align: left;
			margin-top: 2rem;				
			padding: 2rem 2rem 2rem 65%;
			position: relative;
			h2{
				margin: 0;
			}
			img{
				position: absolute;
				bottom: 0;
				left: 2%;
				width: 60%;
			}
		}
		@include breakpoint($large) {	
			padding-left: 50%;
			@include clearfix();	
			img{
				width: 44%;						
			}		
		}			
		@include breakpoint(1290px) {
			margin-top: 6rem;
			padding-left: 530px;			
			img{
				width:  445px;
				left: 50px;
			}
			.text{
				float: left;
				margin: 0;
			}
			.buttonCol{
				float: right;
				transform: translateY(.3rem);
				a:hover{
					background-color: darken($orange, 6%);
				}
			}
		}
		@include breakpoint($max) {
			h2{
				max-width: 32rem;
			}
		}
	}
	// end .peerExchanges banner
}

.whaProjectArchive { // archive listing page

	.mapPreloader {
		@include mapLoader();
	}

	#map {
		#gMap {
			width: 100%;
			height: 33rem;
		}

		.infoWindow{
			img{
				position: absolute;
				top: 0;
				left: 0;
				height: 68px;
				width: 68px;
				display: block;
				margin: 0;
			}
			p{
				float: left;
				margin: 0 0 0 78px;
			}
			a{
				font-weight: bold;
				display: block;
			}
		}
	}
	@at-root .no-js #map{
		display: none;
	}

	aside.rightSideBar .facetwp-facet{
  		max-height: 16.58rem;

		&:last-child{
			border-bottom: 0px none;
		}

	}
	.facetwp-reset{
		background: $brownGrey;
		font-weight: bold;
		color: #fff;
		margin-bottom: 1rem;
		padding: 0.5rem 1rem;
	}
}

.single-awards-cpt{ // single awards post page

	.pageHeader{
		h1 {
			margin-top: 1.5rem;
		}

		.subTitle{
			@include breakpoint($medium) {
				float: left;			
			}
			a{
				color: $orange;
			}
		}
		.subTitle strong.winner:before,
		.subTitle strong.specialMention:before{
	      content: " ";
	      background-color:  #fce952;
	      background: url(img/icon-star.svg), linear-gradient(to bottom, #fce952 0%,#d48c26 100%);
	      background-repeat: no-repeat;
	      background-position: center;
	      width: 36px; 
	      height: 36px;
	      vertical-align: bottom;
	      display: inline-block;
	      margin-right: .5rem;
		}
		.subTitle strong.specialMention:before{
			background-color:  #cecece;
		    background: url(img/icon-star.svg), linear-gradient(to bottom, #ecebed 0%,#b3b3b3 100%);
			background-repeat: no-repeat;
			background-position: center;
		}
		.viewUpdates{
				margin-top:-1rem;
			@include breakpoint($medium) {
				float: right;			
				margin-top: -1.35rem;	
			}
			a:after{
				content: " ";
				background: url('img/icon-arrow.svg') no-repeat;
				background-size: cover;
				transform: rotate(90deg);
				margin: 0 0 0 .5rem;
				width: .8rem;
				height: .8rem;
				display: inline-block;
			}
		}

	}
	.main-content {
		clear:both;
	}

	.postThumbnail{
		width: 100%;
		float: none;
	}
	.projectUpdate{
		border: 1px solid $midGrey;
		padding: $gutter;
		margin: 2rem 0;
		h2{
			color: $darkGrey;
		}
	}


	.contactDetails{
			padding: $gutter;
			margin: 0 0 $gutter 0;		
			border: 2px solid $orange;
			min-height: 14rem;
		@include breakpoint($small) {
			float: left;
			width: 48%;	
			&.secondaryContact{
				float: right;				
			}
		}
		a{
			font-weight: bold;
			color: $brownGrey;
			text-decoration: underline;
		}
		h2{
			margin: 0;
		}
	}

	// .singleContactBox and .whaLogoFiller create an alternative layout if there is only one contact
	.singleContactBox{
		@include breakpoint($small) {
			float: none;
			width: 100%;	
		}	
		@include breakpoint($large) {
			float: left;
			width: 48%;		
		}				
	}
	.whaLogoFiller{
			display: none;
		@include breakpoint($large) {
			border: 1px solid $midGrey;
			text-align: center;
			display: block;
			margin: 0 0 $gutter 0;		
			float: right;
			width: 48%;	
			min-height: 252px;
			min-height: 14rem;
			background: url('img/whaLogo.svg') no-repeat center #fff;
			background-size: 68%;
		}		
		
		
	}

	// Styles for tabbed elements added as part of phase 2 enhancements
	.tab_container {

		clear: left;

		&--1 .tabList > li{
			width: 100%;
		}

		&--2 .tabList > li{
			width: 50%;
		}

		&--3 .tabList > li{
			width: 33.33333%;
		}

		&--4 .tabList > li{
			width: 25%;
		}
	}

	.tabList {
		margin-top: 25px;
		list-style: none;
	}
	
	.tabFrame {
		margin-top: 25px;

		@include breakpoint($medium) {
			margin-top: 0px;
		}
	}
	


}



// Styles base and mobile styles for Awards map.
.map-container {

	position: relative;
	width: 100%;
	height: 33rem;
	display: none;

	#gMap {

		&.loading {
			display: none;
		}	
	}

	.mapPreloader {
		margin: auto;
		position: absolute;
		top: 0; 
		left: 0; 
		bottom: 0; 
		right: 0;
		visibility: hidden;
		
		&.loading {
			visibility: visible;
		}

	}

	// Override styles for the close button
	.infoBox {

		img {
		    position: absolute !important;
    		right: 0px;
			max-width: 10px !important;
			height: auto;
			margin: 10px 10px 0 0 !important;
		}
	}

	.info-window {
		background-color: white;
		width: 360px;
		@include clearfix;
		border-radius: 5px;
		box-shadow: 1px 1px 5px 0px rgba(50, 50, 50, 0.40);

		&__image {
			float: left;
			width: 32%;
			padding-bottom: 36%;
		    background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;
		    border-radius: 5px 0px 0px 5px;
		}

		&__details {
			float: left;
			width: 60%;
			margin-bottom: 0px;
			line-height: 20px;
			margin-top: 25px;
			margin-left: 10px;
		}

		&__name {
			font-weight: bold;
		    font-size: 0.9em;
			margin-bottom: 7px;
			display: block;
		}

		&__year {
			display: block;
		 	font-size: 0.9em;
		}

	}

	// Styles for map overlay 
	&.overlay {
		display: block;
		position: fixed;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background: rgba(255,255,255,1);
	    z-index: 100;
	    overflow: hidden;

		#gMap {
			height: 100% !important;
		}

	}

	.mobile-infobox {
		visibility: hidden;
		max-width: 305px;
		left: 10px;
		bottom: 35px;
		position: fixed;

		.info-window {
			width: 305px;

			&__details {
				margin-top: 10px;
				width: 63%;
			}
		}

	}

	&__mobile-close {
		background: url('img/icon-cross.png') no-repeat center center;
		background-size: cover;
		width: 25px;
		height: 25px;
		display: block;
		position: absolute;
		right: 10px;
		top: 10px;
		z-index: 101;
	}

}

// Trigger for openning awards map for mobile.
.mobile-map-msg {
    margin: 2rem auto;
    background: url(img/openTheAwardsMapBkg.jpg) no-repeat center;
    width: 100%;
    height: 13.5rem;
    position: relative;
    .button{
	    transform: translate(-50%,-50%);
	    left: 50%;
	    top: 50%;
	    min-width: 13rem;
	    position: absolute;
    }
}

.behind-overlay {
	overflow: hidden;
}


ul.mapKey{
	margin: 1rem 0;
	padding-top: .5rem;
	border-bottom: 1px solid $midGrey;
	li{
		display: inline-block;
		font-weight: bold;
		margin: 0 2rem 1rem 0;		
	}
	img{
		margin-right: .3rem;
		vertical-align: middle;
		display: inline-block;
		width: 27px;
	}
}


// Update map layout, removing full screen overlay
@include breakpoint($medium) {

	// Increase width of facet sidebar to span the right column.
	main.hasLeftHandSideBar.hasRightHandSideBar.whaProjectArchive{ 
		
		aside.rightSideBar{
			width: 100%;			
		}
	}

	.whaProjectArchive {
		
		#map #gMap{
    		margin-top: 1rem;
		}
    }

	.mobile-map-msg {
		display: none;
	}

	.map-container {
		display: block;

		&__mobile-close {
			display: none;
		}

		.mobile-infobox {
			visibility: hidden;
		}

	}
	.map-footer {
		margin-top: 2rem;
	}

}

// Adapt tablet layout, allowing tabs and facet elements to span width of column.
@include breakpoint(46.875em) {

	// Increase width of facet sidebar to span the right column.
	main.hasLeftHandSideBar.hasRightHandSideBar.whaProjectArchive .tabContainer {

		.tabList {
			width: 100%;

			li {
				width: 50%;
			}
		}
		.facetwp-template {
			width: 100%;
			border: 0px none;
		}

	}

}

// Overrides 3 column layout from _global.scss line 226
@include breakpoint(64.0625em) {

	// Increase width of facet sidebar to span the right column.
	main.hasLeftHandSideBar.hasRightHandSideBar.whaProjectArchive .tabContainer{
		@include clearfix; 
		border: 1px solid $midGrey;

		// Reduce the width of the facet container to fit next to the map/index list
		aside.rightSideBar{
			width: 34%;
			float: none;
			display: inline-block;
			border-left: 1px solid $midGrey;
			padding: 1rem 0rem;	

			.facetwp-facet,
			h4 {
				padding-left: 1rem;
				padding-right: 1rem;
			}

			.facetwp-reset {
				margin-left: 1rem;
    			margin-right: 1rem;
			}

		}

		#map,
		#findProject,
		.tabList {
    		float: left;
			width: 66%;
			border: 0 none;
		}


		.tabList {
			border-bottom: 1px solid $midGrey;

			li{
				border: 0px none;
				width: 50%;
			}
		}

		.facetwp-template {
			width: 100%;
		}

	}
}


