/*! Flickity v1.1.2
http://flickity.metafizzy.co
---------------------------------------------- */

.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus { outline: none; }

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
          tap-highlight-color: transparent;
  -webkit-user-select: none;
     -moz-user-select: none;  
      -ms-user-select: none;
          user-select: none;
}




////  FLICKITY STYLES  - MODIFIED




/* ---- previous/next buttons ---- */

.flickity-prev-next-button {
  position: absolute;
  top: 33%;
  width: 3.5rem;
  height: 3.5rem;
  border: none;
  cursor: pointer;
  background: url(img/svgLoader.php?img=icon-flickityArrowWhite.svg&fill=%23ffffff) no-repeat center center $green; // fill with $hotPink hex

}

.flickity-prev-next-button:hover { background-color: $red; }

.flickity-prev-next-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #09F;
}

.flickity-prev-next-button:active {
  filter: alpha(opacity=60); /* IE8 */
  opacity: 0.6;
}

.flickity-prev-next-button{
    &.previous {
      transform: translateY(-33%);
      left: 0;
    }
    &.next {
      right: 0rem;
      transform: translateY(-33%) rotate(180deg); 
    }
  @include breakpoint(1510px){
    &.previous { left: -1rem; }
    &.next { right: -1rem; }
  }

}

  
  


/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: -1rem;

}
.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: -1rem;
}

.flickity-prev-next-button:disabled {
  background-color: $midGrey;
  cursor: auto;
}


.flickity-prev-next-button svg {
    display: none;
}

.flickity-prev-next-button .arrow {
  fill: #fff;
}

/* color & size if no SVG - IE8 and Android 2.3 */
.flickity-prev-next-button.no-svg {
  color: #fff;
  font-size: 26px;
}


/* ---- page dots ---- */

.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}

.flickity-rtl .flickity-page-dots { direction: rtl; }

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  filter: alpha(opacity=25); /* IE8 */
  opacity: 0.25;
  cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
  filter: alpha(opacity=100); /* IE8 */
  opacity: 1;
}



