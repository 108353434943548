.page-full-width {

	article {
		max-width: 100%;

		h1 {
			margin-top: 2rem;
		}

	}
}

/* Styles for the full width 404 page template */
.four-zero-four {
	min-height: 300px;

	h1 {
    	padding-top: 2.75rem;
	}
}