

.page-template-archive-news-updates-index {

    .featuredNews{
        margin-bottom: 3rem;
        @include breakpoint(700px) {
            
            .entry.featured {
                float: left;
                width: 33.3%;
                padding: 0 0.625rem;    
                
                &:first-of-type {
                    padding: 0 1.25rem 1rem 0;
                }

                &:last-of-type {
                    padding: 0 0 0 1.25rem;
                }

            }

        }
    }

    // Styles for three column section
    .newUpdatesArchive {

        clear: both;

        section {
            margin-bottom: 3rem;
            & > .title{
                @include breakpoint(0 500px) {
                    margin-left: 1rem;
                }
            }
            .title a{
                margin-top: 0.3125rem;
                display: block;
                font-weight: normal;
                font-size: 1rem;
            }
        }

        @include breakpoint($large) {
            
            section {
                float: left;
                width: 33.3%;
                padding: 0 0.625rem;

                .title a{
                    margin-top: 0;
                    display: block;
                    font-size: 1.1875rem;
                }
                
                &:first-of-type {
                    padding: 0 1.25rem 1rem 0;
                }

                &:last-of-type {
                    padding: 0 0 0 1.25rem;
                }

            }

        }

    }

}
