/*
 * Styles for Snipcart checkout button
 */

 .snipcart {
	display: none;
	width: 100%;
	background: $green;

	.pageFrame{
		max-width: $pageWidth;
		padding: 0 $gutter;
		margin: 0 auto;
		@include clearfix;
	}

	a{
		background: $brownGrey;
		color: #fff;
		font-weight: bold;
		padding: .5rem 1rem;
		display: block;
		float: right;
	}

	img{
		display: inline-block;
	    width: 1.5rem;
	    vertical-align: sub;
	    margin-right: .3rem;
	}
	.snipcart-total-items:before{
		content: "(";
	}
	.snipcart-total-items:after{
		content: ")";
	}

}

/*
 * Styles for Snipcart basket overwrite
 */

#snipcart-main-container a.snipcart-next, #snipcart-main-container a.snipcart-finalize, #snipcart-main-container a.snipcart-mainaction {
    background: $green;
    color: #fff;
    border: none;
    text-shadow: none;
}

#snipcart-footer {
    display: none;
}

#snipcart-header {
	background: $lightGrey;

	#snipcart-title {
		color: #000;
	}
}

#snipcart-close i {
	color: #000;
}
