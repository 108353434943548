.latestTweets{
		padding: $gutter;
		border: 1px solid $midGrey;


	a{
		color: $bodyTextColor;
		text-decoration: underline;
	}
	.followUs{

		img{
			width:32px;
			margin: 0 1rem 0 0;
			vertical-align: middle;
		}
	}
	.tweet{
		time{
			display: block;
			color: $darkGrey;
			font-weight: normal;
		}
		a{
			font-weight: bold;
		}
		margin: 0 0 1rem 0;
	}	
}