/*---------------------------------------
* Styles for Back to top button
---------------------------------------*/

.back-to-top {
	background-color: $lightGrey;
    position: fixed;
    right: 1%;
    bottom: 2%;
    padding: 10px 15px;
    opacity: 0;
    z-index: 10;


	&--fade-in {
		opacity: 0.75;

		&:hover {
			opacity: 1;
		}
	}

	&--fade-out {
		opacity: 0;
	}

	&__text {
		font-weight: bold;
		color: $green;
		margin-right: 10px;
		display: none;
	}
	
	&__icon {
		content: "";
		background: url('img/icon-arrow-up.svg') no-repeat;
		background-size: contain;
		display: inline-block;
		width: 1rem;
		height: 1rem;
		color: $brownGrey;    
		vertical-align: bottom;
	}

	@include breakpoint (max-width 1549px) {
		&--bottom {
			position: relative;
			display: block;
			bottom: 0%;
			float: right;
		    margin: 0rem 0rem 1rem 0rem;
		    opacity: 1;

			.back-to-top__text {
				display: inline-block;
			}

		}
	}

}

/* Once the page is wide enough, display text next to the icon */

@include breakpoint (1800px) {

	.back-to-top {
		padding: 10px 20px;

		&__text {
			display: inline-block;
		}

	}


}