.featuredNews{
  @include breakpoint($small) { 

    h2{
      display: none;
    }
  }
}


/*  .wrapper > h1{
    margin-top: 2.5rem; // extra style to position the first h1 on the page when layout is modified by JS
  }
*/

/*
.featuredNews{
  background-color: $lightGrey;
  margin: 0 -1.5rem 2rem -1.5rem;
  padding: 1rem 1rem 1rem 1.5rem;

  .entry:last-of-type{
    border: 0;
  }


  @include breakpoint($small) { 
    background: none;
    padding: 0;
    @include clearfix();
    border-bottom: 1px solid $midGrey;
    margin: 0 0 3rem 0;

    h2{
      display: none;
    }

  }
}
.featuredNews.largeLayout{
  .entry{
        float: left;
        border-bottom: 0;
        width: 33.3%;
        // all this weird padding adds up to 2rem gutters
        padding: 0 0.625rem; 
        &:first-of-type{
          padding: 0 1.25rem 0 0;
        }
        &:last-of-type{
          padding: 0 0 0 1.25rem;
        }
        a.thumbnail{
          position: static;
          @include clearfix();
          width: 100%;
        }      
        .text{
        }
  }
}
*/