.gallery-main, .gallery-nav{ // common styles applied to bot the gallery and nav
	img{
		display: block;
	}
}
.gallery-main{ // styles applied to bot the gallery
	margin: 0 0 .5rem 0;

	.gallery-item{
		display: block;
		width: 100%;
		position: relative;
	}
	img{
		width: 100%;
	}
	figcaption{
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		padding: .5rem;
		display: block;
		background: #000;
		background: rgba(0, 0, 0, 0.7);
		color: #fff;
	}
}
.gallery-nav{ // styles applied to bot the nav
	margin: 0 2.5rem 3rem 2.5rem;
	.flickity-prev-next-button{
		width: 2rem;
		height: 100%;
	}
	.flickity-prev-next-button.previous {
	  left: -2.5rem;
	}
	.flickity-prev-next-button.next {
	  right: -2.5rem;
	}	
	.gallery-item{
		width: 25%;
		margin-right: .5rem;
		cursor: pointer;
	}
	img{
		width: 100%;
	}	
	.gallery-item.is-selected:after{
		content: " ";
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		background: rgba(0, 0, 0, 0.4);
	}
}
@at-root .no-js .gallery-nav{
	display: none;
}

