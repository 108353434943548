/*
Theme Name: World Habitat WordPress Theme
Author: Cite
Author URI: https://www.cite.co.uk/
Description: World Habitat WordPress Theme by Cite DMS Ltd
Version: 1.0
*/


// PROJECT VARIABLES

$bodyFont : "Harmonia Sans W01", sans-serif;


$brownGrey		: #3c3a35;
$lightGrey		: #f1f1f1;
//$midGrey		: #797979;
$midGrey		: #d5d5d5;
$darkGrey		: #919191;
$hotPink	 	: #e2407b;
$red	 		: #d20a36;
$orange			: #ff8200;
$green			: #00bfb3;
$bodyTextColor	: $brownGrey;
$bodyLinkColor 	: $hotPink;




$imageHover		: rgba(72, 39, 30, 0.2); // used when rolling over image links

$pageWidth		: 1430px;
$contentWidth	: 1000px;
$gutter			: 1rem;


// Navigation variables

$navVerticalPadding: 1rem;
$navHorizontalPadding: $gutter;

$burgerBkgColour		: $hotPink;
$navLinkBkgColour      	: $lightGrey;
$navLinkColour          : $brownGrey;
$navLinkCurrentColour	: #fff;
$navLinkCurrentBkgColour: $hotPink;

// rebase variables
$selectionBkg : $green;
$selectionTxt : #fff;


@import "../bower_components/rebase-reset-scss/rebase"; // Include custom reset (uses SASS variables above)
@import "../bower_components/breakpoint-sass/stylesheets/breakpoint"; // include Breakpoint-sass


// Breakpoint-sass setup

//$breakpoint-no-query-fallbacks: true;
@include breakpoint-set('no query fallbacks', true);

/*
$nav:	  705px, 'no-query' '.no-mq'; // these get applied to ie7&8 as well
$small:   500px, 'no-query' '.no-mq'; 
$medium:  750px, 'no-query' '.no-mq'; 
$large:   950px, 'no-query' '.no-mq';
$xlarge:  1200px, 'no-query' '.no-mq';
*/

$small:   31.250em;  //500px
$nav:	  44.063em;  //705px
$medium:  46.875em;  //750px
$large:   59.375em;  //950px
$xlarge:  75.000em;  //1200px

$max:	  $pageWidth; 



// PARTIALS - Include for each component or page


@import "global";				// common styles
@import "mixins";				// For Publications pages
@import "jsBreakpoints";		// used in javascript to detect which breakpoint we're on
@import "navigation";			// burger nav and desktop menu styles
@import "typography";			// reusable font styles
@import "forms";				// forms including GravityForms style
@import "search";				// search form in header and results page
@import "sidebar";				// left hand sidebar - subnav, image links
@import "cImageBanner";			// 3 image strip that appears at top of ssome templates
@import "cSidebarBanners";		// sidebar image links to other parts of the site
@import "cShare";				// custom share widget
@import "cEntry";				// Base HTML/CSS for archive listing pages, latest news, etc
@import "cLatestAndRelated";	// latest news/ realated posts widget appears toward bottom of templates in various configurations
@import "cFeaturedNews";		// Featured news widget appears at top of Archive pages
@import "cLatestTweets";		// Twitter widget in sidebar
@import "cTabs";				// Tabs used on Awards CPT and Programme Detail page
@import "cX4HeaderLinkBanners";	// grid of 4 image banners used on Our Programmes front page
@import "flickity";				// Flickity carousel plugin default css
@import "cCarousel";			// image links displayed in Flickity Carousel - use on Home and othe Front pages
@import "cGallery";				// image gallery powered by Flickity - apply with shortcode
@import "cModal";				// Styles for modal element. 
@import "cNewsUpdatesFeatured";	// For News Updates template

@import "snipcart-checkout";	// Styles for Snipcart basket elements
@import "contactUs-mailingList";// Styles for the contact us/mailing list forms and custom layout
@import "page-full-width"; 		// Styles for the full width template

@import "back-to-top";			// Styles for Back to top button
@import "showCommentsButton";	// Styles for showCommentsButton (appears next to Share button ehrn Disqus loaded via JS)

@import "awards";				// For World Habitat Awards pages
@import "publications";			// For Publications pages
@import "home";					// For Home page
@import "about";				// For About page
@import "programmes";			// For Programme page
@import "page-signup-updates";	// For newsletter/mailpoet signup page
@import "news-updates";			// For News Updates template

@import "wysiwygRules";			// wysiwyg rules


