.page-template-page-signup-updates {

	h1 {
		margin: 2rem 0 1rem 0;
	}

	article {
  		@include breakpoint($medium) { 		
			width: 64.5%;
			float: left;

			.hideForDesktop{
				display: none;
			}
		}

		img{
			margin-bottom: 2rem;
		}		
		.newsletterTitle:before, .alertsTitle:before{
			content: " ";
		 	display: inline-block;
		 	vertical-align: middle;
		 	margin: -5px .75rem 0 0;
		 	background: url('img/envelope.svg') no-repeat center center $green;
		 	float: left;
		 	border-radius: 6px;
		 	width: 2.5rem;
		 	height: 2.5rem;
		 }
		 .alertsTitle:before{
		 	background-image: url('img/beacon.svg');
		 }
	}

	.signupForm{
			margin: 0;
  		@include breakpoint($medium) { 		
			width: 30%;
			float: right;
		}

		.gform_wrapper{
			margin-top: 0;
			max-width: 100%;

			input.large{
				width: 100%;
			}
			.gfield_checkbox{
				margin: 1.5rem 0;
				li{
					margin: 0 0 .5rem 0;
				}
				label{
					margin: 0 !important;
					font-size: inherit;
					display: inline-block !important;
				}
			}
			#field_1_12 .validation_message{
				margin-top: -20px;
			}
			.gfield.mailpoet{
				// hide all checkboxes and labels
				margin-top: -16px !important;
				.gfield_checkbox input, .gfield_checkbox label{
					display: none !important;
					padding-left: 4px;
				}
				// show Spanish chkbox and label
				@at-root body.lang-es #mailpoet_gf_subscribe_list_4{
					display: inline-block !important;
					& + label{
						display: inline-block !important;		
					}
				}
				// show French chkbox and label
				@at-root body.lang-fr #mailpoet_gf_subscribe_list_5{
					display: inline-block !important;
					& + label{
						display: inline-block !important;		
					}
				}
				// show English chkbox and label
				@at-root body.lang-en #mailpoet_gf_subscribe_list_3{
					display: inline-block !important;
					& + label{
						display: inline-block !important;		
					}
				}								

			}
		}
		.gform_footer{
			border-top: 1px solid $lightGrey;
			text-align: right;
			padding-top: 1.75rem;
			position: relative;
			img.gform_ajax_spinner{
				position: absolute;
				left: 0;
				top: 2.4rem;
			}			
		}
		.validation_error{
		    font-size: 100% !important;
		    font-weight: normal !important;
		    border: 0px none !important;
		    padding: 1em 0px 1em 20px !important;
		    color: red !important;			
		}
		.gform_wrapper li.gfield.gfield_error{
			background: none;
			border: 0;
			padding: 0;
			padding-right: 16px !important;
		}
		.gform_wrapper .field_description_below .gfield_description{
			padding-top: 0;
			color: red;
		}
	}
}

