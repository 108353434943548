.entry, .textEntry{ // text entry is the simple variation for Related Publications 
    @include clearfix();
    margin: 0 0 2rem 0;
    display: block;

    .title, p{
      margin: 0;
    }
    .title{
      margin-bottom: 1rem;
    }
    time{
      font-weight: bold;
    }
    
    .subTitle{
      font-weight: bold;

      .divider-tan {
        color: $midGrey;
      }

    }
    a:hover{
      text-decoration: none;
      h3{
        text-decoration: underline;
      }
    }
}


.entry, .entry.altLayout{
    @include clearfix();
    padding: 0 0 1.5rem 0;   
    margin: 0 0 1.5rem 0;
    display: block;

    &:last-of-type{
      border-bottom: none;
      padding-bottom: 0;
    }
    .thumbnail{
      width: 100%;
      
      display: block;
      img{
        width: 100%;
        display: block;
      }
        
    }
    .text{
        border: 1px solid $midGrey;
        border-top: 0;
        padding: 1.5rem 1rem;
    }
}


// variation for awards listing page
.whaProjectArchive .entry{
  .subTitle strong{
    color: $bodyTextColor;
  }
}


.entry.winner, .entry.specialMention{
  position: relative;
  .title{
      padding-right: 3.2rem;
  }
  &:before{
      content: " ";
      position: absolute;
      background-color:  #fce952;
      background: url(img/icon-star.svg), linear-gradient(to bottom, #fce952 0%,#d48c26 100%);
      background-repeat: no-repeat;
      background-position: center;
      width: 29px; 
      height: 36px;
      right: 1rem;
      top: 0;
    @include breakpoint(500px) { 
      top: -1.5rem;
    }
  }
}

.entry.specialMention{
  &:before{
      background-color:  #cecece;
      background: url(img/icon-star.svg), linear-gradient(to bottom, #ecebed 0%,#b3b3b3 100%);
      background-repeat: no-repeat;
      background-position: center;
  }
}

// WHA section overides
.whaPage .entry,
.whaPage .textEntry{
  a h3{
    color: $brownGrey;
    text-decoration: underline;
  }
}



// ALT Layout - shorter, like the old BSHF site but with small screen layout from above

.entry.altLayout{
    margin-left: 1rem;
    margin-right: 1rem;
  @include breakpoint(500px) { 
    max-width: none;
    border-bottom: 1px solid $midGrey;
    margin-left: 0;
    margin-right: 1rem;
  } 

  .thumbnail{

    @include breakpoint(500px) { 
      background: transparent;
      padding: 0;
      margin: 0 0 0 0;
      width: 40%;
      float: left;
      max-width: 130px;
    }
  }

  .text{

    @include breakpoint(500px) { 
      padding: 0;  
      border: none;
      padding-left: 1rem;      
      width: 60%;
      float: left;
      padding-bottom: 1rem;
    }

  }

}
// Featured layout variation. Used on main News listing page 

.entry.featured{
  border: none;

  .text {
    background-color: $hotPink;
    position: relative;
    border: 0;
  }
  .postLabel{
    display: inline-block;
    background-color: $brownGrey;
    color: #fff;
    font-weight: bold;
    padding: .4rem 1rem;
    position: absolute;
    top: -2.2rem;
    left: 0;
  }  
  h3{ color: #fff;}
  p.subTitle{
    color: #fff;
  }
  p.subTitle a{
    color: #fff;
    text-decoration: underline;
  }

  @include breakpoint(500px 700px) { 
    padding: 0;
    max-width: none;
    margin-right: 0;
    position: relative;

    .thumbnail{
      width: 220px;
      max-width: 100%;
    }
    .text{
      position: absolute;
      left: 220px;
      right: 0;
      bottom: 0rem;
      padding: 1rem;
      height: 11.55rem;
      width: auto;
    }

  }
  @include breakpoint(700px) {   
    margin-right: 0;
    .thumbnail{
      float: none;
      width: 100%;
      max-width: none;
    }
    .text{
      float: none;
      width: 100%;
      padding-top: 1rem;
      padding-right: 1rem;
    }
    .postLabel{
      padding: 1rem;
      top: -3.4rem;
    }
  }
  @include breakpoint(1000px) {   
    .thumbnail{
      //border: 2px solid yellow;
      padding-bottom:80%;
      overflow: hidden;
      position: relative;
      img{
        z-index: -1;
        top: 50%;
        transform: translateY(-50%);
        //left: 10px;
        position:absolute;
        width:auto;
        min-width:100%;
        min-height:100%;
      }
    }
  }
}

// Optional class to add ellipsis (... on long titles) to textEntries 
// this can casue problems - only currently applied to top news page where there are thre columns and title get out of proportion
.entry.ellipsis, .textEntry.ellipsis{
  .title{
    margin-bottom: 1rem;
    display: block;
    display: -webkit-box;
    max-height: 5.25rem;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;         
  }
}