.sidebarBanner{
			display: block;
			margin:  0 0 1rem 0;
			position: relative;
	    @include breakpoint($small) {		
	    	float: left;
	    	width: 50%;
	        padding: 0 0.625rem; 
	      &:first-of-type{
	        padding: 0 1.25rem 0 0;
	      }
	      &:last-of-type{
	        padding: 0 0 0 1.25rem;
	      }			    	
	    }			
	    @include breakpoint($medium) {
	    	float: none;
	    	width: 100%;
	    	padding: 0 !important;
	    }	    

	a{
			display: block;
	    @include breakpoint($small) {		
	    	background-position: center;
	    	background-size: cover;
	    }				
	}	    	
	a:hover:before {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: $imageHover;
		transition: background .3s linear;
	}		
	img{
			display: block;
			width: 100%;
		@include breakpoint($small) {
			visibility: hidden;	    		
		}    		
	}

	span.title{
		background: $brownGrey;
		color: #fff;
		padding: .5rem 1.5rem;
		line-height: 1.3;
		position: absolute;
		bottom: 0;
		font-weight: bold;
	}
	a:hover img{
		opacity: .90;
	}

	span.credit{
		top: 0;
		bottom: auto;
	}
}